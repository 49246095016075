import React, { Component } from 'react';
import { lang } from '../../Language';
import { langFormat } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NativeSelect from '@mui/material/NativeSelect';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Link from '@mui/material/Link';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ImagePreviewDialog from './ImagePreviewDialog';
import IsrEmail from './IsrEmail';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { dateParser } from '../../HelperMethods';

const styles = theme => ({
    reportSection: {
        marginBottom: 20,
        width: 900
    },
    detailsTable: {
        width: 'auto'
    },
    detailsTableRow: {
        height: 'auto'
    },
    detailsTableLabel: {
        textAlign: 'right',
        fontWeight: 'bold',
        border: 'none',
        padding: '1px 10px 1px 5px'
    },
    detailsTableValue: {
        border: 'none',
        padding: '1px 5px 1px 10px'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '3px'
    },
    tableHead: {
        fontSize: 14,
        height: 40,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center'
    },
    tableRow: {
        height: 25,
        paddingLeft: 3,
        paddingRight: 3,
        whiteSpace: 'nowrap'
    },
    tableValue: {
        padding: '0px 3px 0px 3px'
    },
    detailsSectionLabel: {
        display: 'flex',
        alignItems: 'baseline',
        '& button': {
            marginLeft: 20
        }
    },
    detailsAreaCheckbox: {
        padding: 0,
        marginLeft: -3
    },
    componentDetailsOuterWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        width: 900
    },
    componentDetailsInnerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap'
    },
    ticketComponentStatusSelect: {
        fontSize: 'small',
        marginLeft: 10,
        height: 19
    },
    link: {
        textDecoration: 'underline',
        padding: 0,
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: 'transparent',
        }
    }
});

class TicketReportDetails extends Component {
    displayName = TicketReportDetails.name

    formatFileSize = (bytes) => {
        if (bytes === 0) return '0 B';
        var k = 1024;
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    setProofReceived = (event) => {
        let { currentReport, updateReport } = this.props;
        currentReport.ticket.proofReceived = event.target.checked;

        fetch(`SvrIsr/SetProofReceived?tid=${currentReport.ticket.id}&isReceived=${event.target.checked}`, { method: 'post', credentials: 'same-origin' })
            .then(response => response.text())
            .then(data => {
                if (data) {
                    data = JSON.parse(data, dateParser);
                    currentReport.ticket.proofReceivedOn = data.proofReceivedOn;
                    updateReport(currentReport);
                }
            });
    }

    handleStatusChange = (event) => {
        this.openStatusDialog(event.target.selectedOptions[0].dataset.componentid);
    };

    openStatusDialog = (tcid) => {
        let { currentReport } = this.props;
        var component = currentReport.ticket.components.find(c => c.id === Number(tcid));
        if (component) {
            component.openStatusDialog = true;
        }
        this.props.updateReport(currentReport);
    }

    closeStatusDialog = (tcid, acceptChange) => {
        let { currentReport } = this.props;
        var component = currentReport.ticket.components.find(c => c.id === tcid);
        if (component) {
            component.openStatusDialog = false;
            if (acceptChange) {
                var componentStatusId = document.getElementById('componentStatusId-' + tcid);
                if (componentStatusId !== null && componentStatusId !== undefined && componentStatusId.selectedOptions !== undefined && componentStatusId.selectedOptions !== null && componentStatusId.selectedOptions.length > 0) {
                    fetch(`SvrIsr/ChangeTicketComponentStatus?tcid=${tcid}&newStatus=${componentStatusId.selectedOptions[0].value}`, { method: 'post', credentials: 'same-origin' })
                        .then(response => response.text())
                        .then(data => {
                            if (data) {
                                data = JSON.parse(data, dateParser);
                                currentReport.ticket.proofReceivedOn = data.proofReceivedOn;
                                this.props.updateReport(currentReport);
                            }
                        });
                }
            }
            else {
                this.props.updateReport(currentReport);
            }
        }
    }

    getNewComponentStatus = (tcid) => {
        var componentStatusId = document.getElementById('componentStatusId-' + tcid);
        if (componentStatusId !== null && componentStatusId !== undefined && componentStatusId.selectedOptions !== undefined && componentStatusId.selectedOptions !== null && componentStatusId.selectedOptions.length > 0) {
            return componentStatusId.selectedOptions[0].text;
        }
        return "";
    }

    renderTicketInformation = (classes, currentReport, ticket) => {
        let ticketDetails = "";
        let ticketHistory = "";
        let { userInfo } = this.props;
        ticketDetails =
            <div id="ticketDetails" className={classes.reportSection}>
                <div className={classes.detailsSectionLabel}>
                    <Typography variant='h6'>{langFormat("ticketDetailsHeader", ticket.id)}</Typography>
                    {ticket.materialTrackingNumber && ticket.materialTrackingNumber.length > 0 &&
                        [<Typography key="materialTrackingNumber" variant='h6'> - {ticket.materialTrackingNumber}</Typography>]
                }

                {!userInfo.isClientAdminAndHasPublishersAssigned && <IsrEmail currentReport={currentReport} />}
                </div>
                <Table className={classes.detailsTable}>
                    <TableBody>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("status")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.status}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("ticketType")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.mediaType}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("submissionId")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.submissionId}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("name")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.name}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("headline")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.headline}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("publisher")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.publisherName}</TableCell>
                        </TableRow>
                        {ticket.publisherNotes && ticket.publisherNotes.length > 0 &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("publisherNotes")}</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.publisherNotes}</TableCell>
                            </TableRow>]
                        }
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("advertiser")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.advertiser}</TableCell>
                        </TableRow>
                        {ticket.advertiserNotes && ticket.advertiserNotes.length > 0 &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("advertiserNotes")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.advertiserNotes}</TableCell>
                            </TableRow>]
                        }
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("publication")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.publicationName}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("issue")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.issueName}</TableCell>
                        </TableRow>
                        {ticket.isPrint &&
                            [<TableRow key="ticketSize" className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("size")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>
                                    {ticket.size &&
                                        <React.Fragment>
                                            <span>{ticket.size.name}</span>
                                            {ticket.size.displayHeight !== 0 && ticket.size.displayWidth !== 0 &&
                                                [<span key="ticketSizeValue"> {ticket.size.displayWidth} x {ticket.size.displayHeight}</span>]
                                            }
                                        </React.Fragment>
                                    }
                                </TableCell>
                            </TableRow>]
                        }
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("product")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.product}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("proofIncluded")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.proofIncluded}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("proofReceived")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>
                            <Checkbox
                                    disabled={this.props.userInfo.isClientAdminAndHasPublishersAssigned}
                                    name="proofReceived"
                                    value={ticket.proofReceived.toString()}
                                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                    checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                    checked={ticket.proofReceived} onChange={this.setProofReceived} className={classes.detailsAreaCheckbox}
                                />
                                {ticket.proofReceived &&
                                    [<span style={{ verticalAlign: 'inherit', paddingLeft: 5 }}>
                                        {ticket.proofReceivedOn !== null ? `${ticket.proofReceivedOn.toLocaleDateString()} ${ticket.proofReceivedOn.toLocaleTimeString()}` : ""}
                                    </span>]
                                }
                            </TableCell>
                        </TableRow>
                        {!ticket.isPrint &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("fileType")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.fileFlexibilityDesc}</TableCell>
                            </TableRow>]
                        }
                        {ticket.staticResolutionTypeDescVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("resolution")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.staticResolutionTypeDesc}</TableCell>
                            </TableRow>]
                        }
                        {ticket.companyNameVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("companyName")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.companyName}</TableCell>
                            </TableRow>]
                        }
                        {ticket.titleVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("title")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.title}</TableCell>
                            </TableRow>]
                        }
                        {ticket.descriptionVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("description")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.description}</TableCell>
                            </TableRow>]
                        }
                        {ticket.tagsVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("tags")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{ticket.tags}</TableCell>
                            </TableRow>]
                        }
                        {ticket.urlsVisible &&
                            [<TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("urls")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>
                                    {ticket.urls.map((url) => {
                                        return (
                                            <div style={{ display: 'block' }}>{url}</div>
                                        );
                                    })}
                                </TableCell>
                            </TableRow>]
                        }
                        {ticket.proofRequest !== null &&
                            [<React.Fragment>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("proofRequestId")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>{ticket.proofRequest.id}</TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("proofRequestCopies")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>{ticket.proofRequest.copies}</TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("proofRequestEmail")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>{ticket.proofRequest.email}</TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("proofRequestIsActive")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>{ticket.proofRequest.isActive}</TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("proofRequestLastChangedOn")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>{ticket.proofRequest.lastChangedOn}</TableCell>
                                </TableRow>
                            </React.Fragment>]
                        }
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("contactName")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.contactFirstName} {ticket.contactLastName}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("phone")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.contactPhone}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("email")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.contactEmail}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("createdOn")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.createdOn}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("createdBy")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.createdBy}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("lastChangedOn")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.lastChangedOn}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("lastChangedBy")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.lastChangedBy}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("submittedOn")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>{ticket.submittedOn}</TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("submittedLate")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                    checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                    checked={ticket.submittedLate} disabled className={classes.detailsAreaCheckbox}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.detailsTableRow}>
                            <TableCell className={classes.detailsTableLabel}>{lang("deliveryExtension")}:</TableCell>
                            <TableCell className={classes.detailsTableValue}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                    checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                    checked={ticket.needsProofDeliveryExtension} disabled className={classes.detailsAreaCheckbox}
                                />
                            </TableCell>
                        </TableRow>
                        {ticket.isPrint &&
                            [<React.Fragment key="billable">
                                {this.props.userInfo.isClientAdminAndHasPublishersAssigned ? '' :
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("isBillable")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                checked={ticket.isBillable} disabled className={classes.detailsAreaCheckbox}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    }
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("sendFoldingDummy")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                            checked={ticket.sendFoldingDummy} disabled className={classes.detailsAreaCheckbox}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("precheckReports")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.components.map((component) => {
                                            return (
                                                component.precheckReportExists ?
                                                    [<div>
                                                        <a href={"file/StreamFile?fileType=PrecheckReport&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('precheckReport')} - {component.id}</a>
                                                    </div>]
                                                    : ""
                                            );
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("preflightReports")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.components.map((component) => {
                                            return (
                                                component.preflightReportExists ?
                                                    [<div key={component.id}>
                                                        <a href={"file/StreamFile?fileType=PreflightReport&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('preflightReport')} - {component.id}</a>
                                                    </div>]
                                                    : ""
                                            );
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("softCropPdf")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.components.map((component) => {
                                            return (
                                                component.softCropExists ?
                                                    [<div key={component.id}>
                                                        <a href={"file/StreamFile?fileType=SoftCropPdf&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('component')} - {component.id}</a>
                                                    </div>]
                                                    : ""
                                            );
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("hardCropPdf")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.components.map((component) => {
                                            return (
                                                component.hardCropExists ?
                                                    [<div key={component.id}>
                                                        <a href={"file/StreamFile?fileType=HardCropPdf&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('component')} - {component.id}</a>
                                                        {component.isPdfSplit && component.isSplittingSpreadComplete &&
                                                            component.pageSuffixes.map((page, index) => {
                                                                return (
                                                                    <a key={`hardCropPage-${index}`} id="hardCropPage" href={"file/StreamFile?fileType=HardCropPagePdf&id=" + component.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                );
                                                            })
                                                        }
                                                    </div>]
                                                    : ""
                                            );
                                        })}
                                    </TableCell>
                                </TableRow>
                                {ticket.marksShouldBeAddedToHardCrop &&
                                    [<TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("hardCropPdfWithCropMarks")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            {ticket.components.map((component) => {
                                                return (
                                                    component.hardCropWithMarksExists ?
                                                        [<div key={component.id}>
                                                            <a id="hardCropMarksFull" href={"file/StreamFile?fileType=HardCropMarksFull&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('component')} - {component.id}</a>
                                                            {component.isPdfSplit && component.isSplittingSpreadComplete &&
                                                                component.pageSuffixes.map((page, index) => {
                                                                    return (
                                                                        <a key={`hardCropMarksPage-${index}`} id="hardCropMarksPage" href={"file/StreamFile?fileType=HardCropMarksPage&id=" + component.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                    );
                                                                })
                                                            }
                                                        </div>]
                                                        : ""
                                                );
                                            })}
                                        </TableCell>
                                    </TableRow>]
                                }
                                {ticket.isPlateReady &&
                                    [<TableRow key="hardCropPlateReady" className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("plateReadyPdf")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            {ticket.components.map((component) => {
                                                return (
                                                    component.isPlateReady ?
                                                        [<div key={component.id}>
                                                            <a id="hardCropPlateReady" href={"file/StreamFile?fileType=HardCropPlateReadyPdf&id=" + component.id} target="_blank" rel="noopener noreferrer">{lang('component')} - {component.id}</a>
                                                            {component.isPdfSplit && component.isSplittingSpreadComplete ?
                                                                component.pageSuffixes.map((page, index) => {
                                                                    return (
                                                                        <a key={`hardCropPlateReadyPdf-${index}`} id="hardCropPlateReadyPdf" href={"file/StreamFile?fileType=HardCropPlateReadyPagePdf&id=" + component.id + "&index=" + index} target="_blank" rel="noopener noreferrer"> {page}</a>
                                                                    );
                                                                })
                                                                : ""
                                                            }
                                                        </div>]
                                                        : ""
                                                );
                                            })}
                                        </TableCell>
                                    </TableRow>]
                                }
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("confirmationReceipt")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.isConfirmationAvailable &&
                                            [<div key="receipt">
                                                <a href={"file/StreamFile?fileType=Receipt&id=" + ticket.id} target="_blank" rel="noopener noreferrer">{ticket.id}</a>
                                            </div>]
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("referenceDocuments")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        {ticket.referenceDocuments.map((refDoc) => {
                                            return (
                                                <div>
                                                    <a key={`refDoc-${refDoc.id}`} href={"file/StreamFile?fileType=RefDoc&id=" + refDoc.id} target="_blank" rel="noopener noreferrer">{refDoc.originalFilename}</a>
                                                </div>
                                            );
                                        })}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.detailsTableRow}>
                                    <TableCell className={classes.detailsTableLabel}>{lang("adSpecsUrl")}:</TableCell>
                                    <TableCell className={classes.detailsTableValue}>
                                        <a href={ticket.publicationAdSpecsUrl} target="_blank" rel="noopener noreferrer">{lang("adSpecs")}</a>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>]
                        }
                    </TableBody>
                </Table>
            </div>;

        ticketHistory =
            <div id="ticketHistory" className={classes.reportSection}>
                <Typography variant='h6'>{langFormat("ticketHistorySectionHeader", ticket.id)}</Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableHead}>{lang('status')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('message')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('application')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('machine')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('time')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticket.history.map((h, index) => {
                            return (
                                <TableRow key={`history_${ticket.id}_${index}`} className={classes.tableRow}>
                                    <TableCell className={classes.tableValue}>{h.status}</TableCell>
                                    <TableCell className={classes.tableValue}>{h.message}</TableCell>
                                    <TableCell className={classes.tableValue}>{h.application}</TableCell>
                                    <TableCell className={classes.tableValue}>{h.machine}</TableCell>
                                    <TableCell className={classes.tableValue}>{h.time}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>;

        return (
            <React.Fragment>
                {ticketDetails}
                {ticketHistory}
            </React.Fragment>
        );
    };

    renderComponentInformation = (classes, currentReport, ticket, component) => {
        let componentDetails = "";
        let componentPreflightResults = "";
        let componentPrecheckResults = "";
        let componentHistory = "";

        componentDetails =
            <div key={component.id} id="ticketComponentDetails" className={classes.componentDetailsOuterWrapper}>
                <Typography variant='h6'>{langFormat("ticketComponentDetailsSectionHeader", component.id)}</Typography>
                <div className={classes.componentDetailsInnerWrapper}>
                    <Table className={classes.detailsTable}>
                        <TableBody>
                            {ticket.isPrint && component.template !== null &&
                            [<React.Fragment key={`componentTemplate_${component.id}`}>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("trimSize")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>{component.template.width} x {component.template.height}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("bleed")}:</TableCell>
                                        {component.template.minBleed === component.template.maxBleed
                                            ? <TableCell className={classes.detailsTableValue}>{component.template.minBleed}</TableCell>
                                            : <TableCell className={classes.detailsTableValue}>{component.template.minBleed} - {component.template.maxBleed}</TableCell>
                                        }
                                    </TableRow>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("safety")}:</TableCell>
                                        {component.template.minSafety === component.template.maxSafety
                                            ? <TableCell className={classes.detailsTableValue}>{component.template.minSafety}</TableCell>
                                            : <TableCell className={classes.detailsTableValue}>{component.template.minSafety} - {component.template.maxSafety}</TableCell>
                                        }
                                    </TableRow>
                                </React.Fragment>]
                            }
                            {ticket.isPrint &&
                                [<React.Fragment key="printSpecificData">
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("colors")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>{component.colors}</TableCell>
                                    </TableRow>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("preflightErrors")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                checked={component.hasPreflightErrors} disabled className={classes.detailsAreaCheckbox}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("preflightWarnings")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                checked={component.hasPreflightWarnigs} disabled className={classes.detailsAreaCheckbox}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.detailsTableRow}>
                                        <TableCell className={classes.detailsTableLabel}>{lang("preflightFlags")}:</TableCell>
                                        <TableCell className={classes.detailsTableValue}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                                                checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                                                checked={component.hasPreflightFlags} disabled className={classes.detailsAreaCheckbox}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>]
                            }
                            <TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("createdOn")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{component.createdOn}</TableCell>
                            </TableRow>
                            <TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("lastChangedOn")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{component.lastChangedOn}</TableCell>
                            </TableRow>
                            <TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("originalFile")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>
                                <a href={"file/StreamFile?fileType=" + (ticket.isPrint ? "OriginalPdf" : "MediaFIle") + "&id=" + component.id} target="_blank" rel="noopener noreferrer">{component.filename}</a>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("fileSize")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>{this.formatFileSize(component.fileSize)}</TableCell>
                            </TableRow>
                            <TableRow className={classes.detailsTableRow}>
                                <TableCell className={classes.detailsTableLabel}>{lang("status")}:</TableCell>
                                <TableCell className={classes.detailsTableValue}>
                                    <div style={{ display: 'flex' }}>
                                        {component.statusDesc}
                                        {currentReport.ticketComponentStatuses !== null &&
                                            <React.Fragment>
                                                <NativeSelect id={"componentStatusId-" + component.id} name="statusId" onChange={this.handleStatusChange} className={classes.ticketComponentStatusSelect} >
                                                    <option value="">{lang("changeStatus")}...</option>
                                                    {currentReport.ticketComponentStatuses.map(status => (
                                                        <option key={status.id} data-componentid={component.id} value={status.id}>{status.name}</option>
                                                    ))}
                                                </NativeSelect>
                                                <Dialog open={component.openStatusDialog} onClose={this.closeStatusDialog} >
                                                    <DialogTitle id="status-dialog-title">{lang('overrideStatusWarningTitle')}</DialogTitle>
                                                    <DialogContent>
                                                        <Typography variant="body2" paragraph>{lang('overrideStatusWarning1')}</Typography>
                                                        <Typography variant="body2" paragraph>{lang('overrideStatusWarning2')}</Typography>
                                                        <Typography variant="body2" paragraph>{langFormat('overrideStatusWarning3', component.id)}</Typography>
                                                        <Typography variant="body2" paragraph>{lang('overrideStatusWarningOldStatus')}: <b>{component.statusDesc}</b></Typography>
                                                        <Typography variant="body2" paragraph>{lang('overrideStatusWarningNewStatus')}: <b>{this.getNewComponentStatus(component.id)}</b></Typography>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button variant='contained' onClick={() => { this.closeStatusDialog(component.id, false); }} color="primary">
                                                            {lang('cancel')}
                                                        </Button>
                                                        <Button variant='contained' onClick={() => { this.closeStatusDialog(component.id, true); }} color="secondary">
                                                            {lang('ok')}
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </React.Fragment>
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    {ticket.isPrint &&
                        [<div key="originalThumnail" style={{ marginLeft: 30 }}>
                            <ImagePreviewDialog showImageThumbnail imageThumbnailSource={"file/StreamFile?fileType=OriginalThumbnail&id=" + component.id} component={component} />
                        </div>]
                    }
                </div>
            </div>;

        componentPreflightResults =
            <React.Fragment>
                {component.preflightReportExists ?
                    [<div key="ticketComponentPreflightResults" id="ticketComponentPreflightResults" className={classes.reportSection}>
                        <Typography style={{ display: 'inline-block' }} variant='h6'>{lang("preflightingResults")}</Typography> -
                        <Typography style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }} variant='body2'>
                            <Link href={`File/StreamFile?fileType=PreflightReport&id=${component.id}`} target="_blank" rel="noopener noreferrer" className={classes.link} alt={component.id}>{` ${lang('preflightReport')}`}</Link>
                        </Typography>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableHead}>{lang('type')}</TableCell>
                                    <TableCell className={classes.tableHead}>{lang('message')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {component.preflightResults &&
                                    component.preflightResults.map((result, index) => {
                                        return (
                                            <TableRow key={`pf_${component.id}_${index}`} className={classes.tableRow}>
                                                <TableCell className={classes.tableValue}>{result.type}</TableCell>
                                                <TableCell className={classes.tableValue}>{result.message}</TableCell>
                                            </TableRow>
                                        );
                                })}
                            </TableBody>
                        </Table>
                    </div>]
                    : ""
                }
            </React.Fragment>;

        componentPrecheckResults =
            <React.Fragment>
                {component.precheckReportExists ?
                    [<div id="ticketComponentPrecheckResults" className={classes.reportSection}>
                        <Typography style={{ display: 'inline-block' }} variant='h6'>{lang("precheckResults")}</Typography> - 
                        <Typography style={{ display: 'inline-block', whiteSpace: 'pre-wrap' }} variant='body2'>
                            <Link href={`File/StreamFile?fileType=PrecheckReport&id=${component.id}`} target="_blank" rel="noopener noreferrer" className={classes.link} alt={component.id}>{` ${lang('precheckReport')}`}</Link>
                        </Typography>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableHead}>{lang('type')}</TableCell>
                                    <TableCell className={classes.tableHead}>{lang('message')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {component.precheckResults &&
                                    component.precheckResults.map((result, index) => {
                                        return (
                                            <TableRow key={index} className={classes.tableRow}>
                                                <TableCell className={classes.tableValue}>{result.type}</TableCell>
                                                <TableCell className={classes.tableValue}>{result.message}</TableCell>
                                            </TableRow>
                                        );
                                })}
                            </TableBody>
                        </Table>
                    </div>]
                    : ""
                }
            </React.Fragment>;

        componentHistory =
            <div id="ticketComponentHistory" className={classes.reportSection}>
                <Typography variant='h6'>{lang("ticketComponentHistorySectionHeader")}</Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableHead}>{lang('status')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('message')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('application')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('machine')}</TableCell>
                            <TableCell className={classes.tableHead}>{lang('time')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {component.history && 
                            component.history.map((h, index) => {
                                return (
                                    <TableRow key={index} className={classes.tableRow}>
                                        <TableCell className={classes.tableValue}>{h.status}</TableCell>
                                        <TableCell className={classes.tableValue}>{h.message}</TableCell>
                                        <TableCell className={classes.tableValue}>{h.application}</TableCell>
                                        <TableCell className={classes.tableValue}>{h.machine}</TableCell>
                                        <TableCell className={classes.tableValue}>{h.time}</TableCell>
                                    </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </div>

        return (
            <React.Fragment key={`component${component.id}`}>
                {componentDetails}
                {componentPrecheckResults}
                {componentPreflightResults}
                {componentHistory}
            </React.Fragment>
        );
    };

    renderProofPaymentInformation = (classes, ticket) => {
        return (
            <div key="proofPaymentInfo">
                <Typography variant='h6'>{lang("ticketProofPaymentSectionHeader")}</Typography>
                {ticket.proofAudits.map((audit, index) => {
                    audit = React.createElement("div", { dangerouslySetInnerHTML: { __html: audit } });
                    return (
                        <React.Fragment key={`audit${index}`}>
                            <div style={{ display: 'block' }}>{audit}</div>
                            <br />
                            <br />
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    render() {
        const { classes, currentReport } = this.props;
        let ticket = currentReport.ticket;

        return (
            <div>
                {this.renderTicketInformation(classes, currentReport, ticket)}
                <div id="ticketComponent" className={classes.reportSection}>
                    <Typography variant='h6'>{lang("ticketComponentDetails")}</Typography>
                    {ticket.components.map((component) => {
                        return (
                            this.renderComponentInformation(classes, currentReport, ticket, component)
                        );
                    })}
                </div>
                {this.renderProofPaymentInformation(classes, ticket)}
            </div>
        );
    }
}

export default withStyles(TicketReportDetails, styles, { withTheme: true });
import React, { Component } from 'react';
import { Navigate } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import NewContinue from './NewContinue';
import NewTicket from './NewTicket';
import MainView from './MainView';
import TicketSubmitted from './TicketSubmitted';
import { TicketStatus } from '../../TicketStatus';
import { ISR_UPLOAD } from './TicketDetailsPrint';

/* The sole purpose of this class is to direct the user to where they belong on the New/Continue tab.
 * If you're visiting this tab for the first time, or navigate directly to /ticket/new, then they go 
 * to the new/continue screen.
 * If you're directly navigating to a ticket ID via /ticket/#TicketId# then we load the ticket data 
 * and direct them to where they belong in the editor.
 * If we already have a ticket in memory, (stored in state of MainLayout) and they just came back to
 * this tab, we take them back to exactly where they were before.
 **/

export class TicketRouter extends Component {
    static getDerivedStateFromProps(props, state) {
        if (!state.loading) {
            let { currentSubmission } = props;
            if (!currentSubmission["ticket"]) {
                //If no ticket is loaded, and we have a ticket in our URL, prepare to load that TicketId.
                let ticketId = TicketRouter.urlTicketId(props);
                if (ticketId) {
                    return { loading: true, needsToLoadId: ticketId };
                }
            }
        }
        return null;
    }
    static getChildPath(props) {
        let currentPath = props.location.pathname;
        let pieces = currentPath.split('/', 3);
        if (pieces.length === 3) {
            return pieces[2];
        }
        return null;
    }
    static urlTicketId(props){
        let cp = TicketRouter.getChildPath(props);
        if (cp) {
            let ticketId = Number(cp);
            if (ticketId > 0) {
                return ticketId;
            }
        }
        return null;
    }

    displayName = TicketRouter.name

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            needsToLoadId: null
        };
    }

    componentDidMount() {
        if (this.state.needsToLoadId) {
            this.loadTicket(this.state.needsToLoadId);
        }
    };

    componentDidUpdate() {
        if (this.state.needsToLoadId) {
            this.loadTicket(this.state.needsToLoadId);
        }
    };

    componentWillUnmount() {
        let { currentSubmission } = this.props;
        if (currentSubmission && currentSubmission.ticket && currentSubmission.ticket.status > TicketStatus.InProgress) {
            //User is navigating away from a completed submission. Clear it out.
            currentSubmission.ticket = null;
            this.props.updateSubmission(currentSubmission);
        }
    };

    loadTicket = (ticketId) => {
        this.setState({ needsToLoadId: null }); //Prevent double fetch due to future state updates.
        fetch(`SvrTicket/GetTicket?ticketId=${ticketId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    //If it's not successful that's fine, setting loading to false will dump them into New/Continue automatically.
                    let { currentSubmission } = this.props;
                    currentSubmission.ticket = data.ticket;
                    if (currentSubmission.ticket.isrUploadPageCount > 0) {
                        currentSubmission.sizeFamily = ISR_UPLOAD;
                    }
                    else if (currentSubmission.ticket.sizeFamilyId > 0) {
                        currentSubmission.sizeFamily = `${currentSubmission.ticket.sizeFamilyId}_null`;
                    }
                    else {
                        currentSubmission.sizeFamily = `0_${currentSubmission.ticket.sizeId}`;
                    }
                    currentSubmission.mediaTypeFields = data.mediaTypeFields;
                    currentSubmission.fileTypes = data.fileTypes;
                    currentSubmission.allowsStaticResolution = data.allowsStaticResolution;
                    currentSubmission.allowsUrls = data.allowsUrls;
                    currentSubmission.requiresUrls = data.requiresUrls;
                    currentSubmission.staticOptions = data.staticOptions;
                    currentSubmission.staticMessage = data.staticMessage;
                    currentSubmission.expandedPanel = 0;
                    currentSubmission.refDocs = null;
                    currentSubmission.fileUploads = [];
                    currentSubmission.reusableComponents = data.reusableComponents;
                    currentSubmission.fixMyFileEnabled = data.fixMyFileEnabled;
                    currentSubmission.isCyberSourceEnabled = data.isCyberSourceEnabled;
                    currentSubmission.useCMYKOption = data.useCMYKOption;
                    currentSubmission.acceptableMediaFileTypes = data.acceptableMediaFileTypes;
                    currentSubmission.showCMYKCheckboxes = data.showCMYKCheckboxes;
                    currentSubmission.flashPanel = -1;
                    currentSubmission.followCursor = data.followCursor;
                    currentSubmission.showRuler = data.showRuler;
                    currentSubmission.transparencyMode = data.transparencyMode;
                    currentSubmission.showCrosshairs = data.showCrosshairs;
                    currentSubmission.showSubInchMarks = data.showSubInchMarks;
                    currentSubmission.showAdSizeOutline = data.showAdSizeOutline;
                    currentSubmission.showDeliveryConfirmationDialog = data.showDeliveryConfirmationDialog;
                    currentSubmission.showAgreementConfirmation = data.showAgreementConfirmation;
                    currentSubmission.wasDeliveryConfirmationDialogShown = false;
                    if (currentSubmission.ticket.sizeId === null &&
                        currentSubmission.ticket.isrUploadPageCount === null &&
                        currentSubmission.ticket.fileTypeId === null) {
                        //Force into edit if they loaded an incomplete ticket from AdShuttle 2.0. Not possible on 3.0.
                        currentSubmission.showEditDetails = true;
                        currentSubmission.ticket.isNew = true;
                    }
                    this.props.updateSubmission(currentSubmission);
                }
                else {
                    //Ticket failed to load - Create an empty ticket so they're dumped to new
                    let { currentSubmission } = this.props;
                    currentSubmission.ticket = {};
                    this.props.updateSubmission(currentSubmission);
                }
                this.setState({ loading: false, needsToLoadId: null });
            });
    };

    render() {
        let { currentSubmission, updateSubmission, showBackgroundDialog } = this.props;

        let routedView = <NewContinue allowAdResubmission={this.props.allowAdResubmission} updateSubmission={updateSubmission} />;
        if (this.state.loading) {
            routedView = <CircularProgress style={{ marginTop: '16px'}} />;
        }
        else if (currentSubmission["ticket"] && currentSubmission.ticket.id > 0) {
            let urlTicketId = TicketRouter.urlTicketId(this.props);
            //Work in progress - Needs to check component status
            if (currentSubmission.ticket.id > 0 && urlTicketId === null) {
                //If we have a ticket in memory already, switch the URL to that ticketId automatically because we're going back there.
                routedView = <Navigate to={{ pathname: `/ticket/${currentSubmission.ticket.id}` }} replace />;
            }
            else if (currentSubmission.ticket.status === TicketStatus.InProgress || currentSubmission.ticket.status === TicketStatus.SubmitFailed) {
                routedView = <MainView location={this.props.location} currentSubmission={currentSubmission} updateSubmission={updateSubmission} showBackgroundDialog={showBackgroundDialog} />;
            }
            else if (currentSubmission.ticket.status > TicketStatus.InProgress) {
                routedView = <TicketSubmitted currentSubmission={currentSubmission} updateSubmission={updateSubmission} />;
            }
            else {
                routedView = '';
            }
        }
        else if (TicketRouter.getChildPath(this.props) === 'new') {
            routedView = <NewTicket currentSubmission={currentSubmission} updateSubmission={updateSubmission} />;
        }
        else if (currentSubmission["ticket"]) {
            //Has an empty ticket, but the ticket has no data so this is a new ticket.
            routedView = <Navigate to={{ pathname: '/ticket/new' }} replace />;
        }

        return (
            <React.Fragment>
                {routedView}
            </React.Fragment>
        );
    }
}

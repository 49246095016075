import React, { Component } from 'react';
import { lang } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    inlineContainer: {
        display: 'inline',
    },
    blockContainer: {
        display: 'block'
    },
    bold: {
        fontWeight: 'bold'
    },
    padding: {
        paddingTop: 10
    },
    float: {
        paddingTop: 20,
        float: 'right',
        clear: 'both',
    },
    spacing: {
        marginLeft: 10
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    flexContainer: {
        display: 'inline-flex',
        float: 'left',
    },
    textArea: {
        marginLeft: 20,
        fontSize: 'small'
    },
    image: {
        minHeight: '200px',
        maxHeight: 'calc(100vh - var(--panel-overhead, 325px))',
        maxWidth: 'calc(90vw - 640px)',
    }
});

const DialogType = Object.freeze({
    None: 0,
    Error: 1
});

class FixedFilePreview extends Component {
    displayName = FixedFilePreview.name

    constructor(props) {
        super(props);
        this.state = {
            dialogType: DialogType.None,
            showDialog: false,
            message: '',
            ticketState: TicketState.None
        };
    }

    componentDidMount = () => {
        const { currentSubmission } = this.props;
        const numOfComponents = currentSubmission.ticket.components.length;
        let usedSpace = document.getElementById('pageBodyArea').offsetTop + (numOfComponents * 54) + 200;
        document.documentElement.style.setProperty('--panel-overhead', `${usedSpace}px`);
    }

    updateStatus = (nextStatus) => {
        let tcid = this.props.currentSubmission.ticket.components[this.props.componentIndex].id;
        fetch(`SvrTicket/UpdateComponentStatus?tcid=${tcid}&statusId=${nextStatus}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex].status = nextStatus;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    proceedWithFixedFile = (ticketComponentId) => {
        fetch(`SvrTicket/ApplyFixedFile?tcid=${ticketComponentId}`, {
            method: 'POST',
            credentials: "same-origin"
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.updateStatus(data.status);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ dialogType: DialogType.Error, showDialog: true, message: lang(data.error) });
                    }
                }
            });
    }

    revertToOriginalFile = (ticketComponentId) => {
        fetch(`SvrTicket/RejectFixedFile?tcid=${ticketComponentId}`, {
            method: 'POST',
            credentials: "same-origin"
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ dialogType: DialogType.Error, showDialog: true, message: lang(data.error) });
                    }
                }
            });
    }

    downloadACopyOfFixedFile = (ticketComponentId) => {
        fetch(`SvrTicket/VerifyIfFixedFileStillExists?tcid=${ticketComponentId}`, { credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    document.getElementById('createdDownloadLink').click();
                }
                else {
                    this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                }
            });
    }

    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    render() {
        const { classes, componentIndex, currentSubmission } = this.props;
        let ticketComponentId = currentSubmission.ticket.components[componentIndex].id;
        let fixedDownloadName = currentSubmission.ticket.components[componentIndex].fixedDownloadName
        return (
            <div className={classes.inlineContainer}>
                <div className={classes.flexContainer}>
                    <img className={classes.image} alt={fixedDownloadName} src={`./File/StreamFile?fileType=FixedFullJpeg&id=${ticketComponentId}`} />
                    <div className={classes.textArea}>
                        <div>
                            <Typography variant="body2"><span>{`${lang('byChoosingTo')} `}</span><span className={classes.bold}>{lang('proceedWithFixedFile')}</span><span>{lang('byChoosingToProceed')}</span></Typography>
                        </div>
                        <div className={classes.padding}>
                            <Typography variant="body2"><span className={classes.bold}>{`${lang('downloadACopy')} `}</span><span>{lang('ifYouWishToReview')}</span></Typography>
                        </div>
                        <div className={classes.padding}>
                            <Typography variant="body2"><span>{`${lang('byChoosingTo')} `}</span><span className={classes.bold}>{lang('revertToOriginal')}</span><span>{lang('byChoosingToRevert')}</span></Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.float}>
                    <a id="createdDownloadLink" style={{ display: 'none' }} href={`./File/StreamFile?fileType=FixedPdf&id=${ticketComponentId}&noinline=true`} target="_blank" rel='noopener noreferrer' download={fixedDownloadName}>{fixedDownloadName}</a>
                    <Button onClick={() => this.downloadACopyOfFixedFile(ticketComponentId)} className={classes.neutralColor} variant='contained'>{lang('downloadACopy')}</Button>
                    <Button onClick={() => this.revertToOriginalFile(ticketComponentId)} className={classes.spacing} variant='contained' color='primary'>{lang('revertToOriginal')}</Button>
                    <Button onClick={() => this.proceedWithFixedFile(ticketComponentId)} className={classes.spacing} variant='contained' color='secondary' autoFocus>{lang('proceedWithFixedFile')}</Button>
                </div>
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.closeDialog}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button key="btnOK" onClick={this.closeDialog} variant="contained" color="secondary" autoFocus>
                            {lang("ok")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
        );
    }
}

export default withStyles(FixedFilePreview, styles);
import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TicketComponentStatus } from '../../TicketComponentStatus';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import OpenInNew from '@mui/icons-material/OpenInNew';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const ZOOM_AREA_WIDTH = 200;  //width in pixels of zoom area.
const LOADING_SPINNY_SIZE = 80;

const styles = (theme) => ({
    positioner: {
        minWidth: 800
    },
    mainArea: {
        minWidth: 800,
        display: 'flex'
    },
    buttons: {
        textAlign: 'right',
        '&> button': {
            marginLeft: 10
        }
    },
    cursorBox: {
        position: 'absolute',
        backgroundColor: 'white',
        border: '1px solid black',
        opacity: 0.4,
    },
    cursorMask: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.0,
        cursor: 'crosshair',
    },
    workArea: {
        display: 'inline-block',
        position: 'relative',
        backgroundColor: 'gray',
        overflow: 'hidden'
    },
    toolArea: {
        display: 'inline-block',
        width: ZOOM_AREA_WIDTH,
        marginLeft: 15,
        marginBottom: 30
    },
    zoomTool: {
        width: ZOOM_AREA_WIDTH,
        height: ZOOM_AREA_WIDTH,
        border: 'solid black 1px',
        overflow: 'hidden',
        position: 'relative'
    },
    infoText: {
        width: ZOOM_AREA_WIDTH,
        fontSize: 13,
        lineHeight: 'inherit',
        marginBottom: 5
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 13
    },
    redHighlight: {
        color: 'red',
        fontSize: 13
    },
    blueHighlight: {
        color: 'blue',
        fontSize: 13
    },
    greenHighlight: {
        color: 'green',
        fontSize: 13
    },
    neutralColor: {
        color: theme.palette.neutral.contrastText,
        backgroundColor: theme.palette.neutral.main,
        '&:hover': {
            backgroundColor: theme.palette.neutral.dark,
        },
    },
    centerImageButton: {
        textAlign: 'center'
    },
    checkbox: {
        padding: 0,
        marginLeft: 20
    },
    doubleClickInfo: {
        fontStyle: 'italic',
        fontSize: 13
    },
    list: {
        paddingLeft: 20,
        marginTop: 0,
        marginBottom: 0
    },
    indent: {
        paddingLeft: 20,
        marginRight: 0
    },
    image: {
        position: 'absolute'
    },
    offsetHeader: {
        paddingTop: 10,
        fontWeight: 'bold'
    },
    offset: {
        paddingBottom: 10
    },
    controlArea: {
        display: 'inline-block',
        width: 225,
        verticalAlign: 'top',
        marginLeft: 10
    },
    topLines: {
        position: 'absolute'
    },
    loadingArea: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        backgroundColor: 'lightgray',
        opacity: 0.7
    },
    loadingSpinny: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: LOADING_SPINNY_SIZE,
        height: LOADING_SPINNY_SIZE
    },
    expandText: {
        marginTop: 10,
        width: 200
    }
});

const NUDGE_DIST = 1 / 256;	//inches to move image when arrow keys are pressed
const PADDING = 1; //inches of padding around the work area

const KEY_1 = 49;
const KEY_2 = 50;
const KEY_3 = 51;
const KEY_4 = 52;
const KEY_5 = 53;
const KEY_6 = 54;
const KEY_7 = 55;
const KEY_8 = 56;
const KEY_9 = 57;
const KEY_NUMPAD_1 = 97;
const KEY_NUMPAD_2 = 98;
const KEY_NUMPAD_3 = 99;
const KEY_NUMPAD_4 = 100;
const KEY_NUMPAD_5 = 101;
const KEY_NUMPAD_6 = 102;
const KEY_NUMPAD_7 = 103;
const KEY_NUMPAD_8 = 104;
const KEY_NUMPAD_9 = 105;
const KEY_LEFT = 37;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_DOWN = 40;

const MILLIMETERS_PER_INCH = 25.4;
const MILLIMETERS = 'Millimeters';

const DialogType = Object.freeze({
    None: 0,
    Error: 1
});

class Positioner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            dragging: false,
            mouseDownX: 0,
            mouseDownY: 0,
            baselineX: 0,
            baselineY: 0,
            showDialog: false,
            dialogType: DialogType.None,
            message: '',
            firstDraw: true,
            invalidated: true,
            isLoading: true,
            workAreaWidth: 0,
            workAreaHeight: 0,
            workAreaDpi: 0,
            imageWidth: 0,
            imageHeight: 0,
            zoomAreaWidth: 0,
            zoomAreaHeight: 0,
            zoomAreaDpi: 0,
            zoomRatio: 0,
            ticketState: TicketState.None
        };
    }

    componentDidMount() {
        //Fetch positioner and image data from server
        fetch(`SvrTicket/Positioner?tcid=${this.props.currentSubmission.ticket.components[this.props.componentIndex].id}`,
            { method: 'POST', credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.initPositioner(data.settingsAndData);
                }
                else {
                    this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) })
                }
            });
    }

    //Initialize the positioner and setup the ratios, DPIs, widths, heights needed to correctly scale everything in the positioner, 
    //as well as the checkboxes' init state.
    initPositioner = (data) => {
        let isInDialog = this.props.isExpanded;
        let componentIndex = this.props.componentIndex;
        let mainArea = document.getElementById(`mainArea-${componentIndex}`);
        let workArea = document.getElementById(`workArea-${componentIndex}`);
        let fromTop = this.getOffsetfromTopOfDocument(workArea);
        let fromLeft = this.getOffsetfromLeftOfDocument(workArea);
        let numberOfComponents = this.props.currentSubmission.ticket.components.length;
        let availableVerticalSpace = isInDialog ? window.innerHeight - 150 : window.innerHeight - fromTop - (numberOfComponents * 30 + 100);  //the 100 just accounts for extra paddings/margins.
        let availableHorizontalSpace = isInDialog ? window.innerWidth - 800 : window.innerWidth - fromLeft - 525;
        let heightMultiplier = 1;
        let workAreaWidth = Math.round(Math.max(data.trimWidth, data.imageWidth / data.imageDpi)) + PADDING;
        let workAreaHeight = Math.round(Math.max(data.trimHeight, data.imageHeight / data.imageDpi)) + PADDING;
        let ratio = workAreaWidth / workAreaHeight;
        if (!isInDialog && ratio >= 1) {
            //wider than it is tall
            let newavailableVerticalSpace = workAreaHeight * availableHorizontalSpace / workAreaWidth;
            if (newavailableVerticalSpace < availableVerticalSpace) {
                availableVerticalSpace = newavailableVerticalSpace;
            }
        }

        if (availableVerticalSpace > mainArea.clientHeight) {
            heightMultiplier = availableVerticalSpace / mainArea.clientHeight;
        }
        let workAreaDpi = Math.round(Math.min(mainArea.clientWidth * heightMultiplier / workAreaWidth, mainArea.clientHeight * heightMultiplier / workAreaHeight));
        workAreaWidth = workAreaWidth * workAreaDpi;
        workAreaHeight = workAreaHeight * workAreaDpi;
        let imageWidth = Math.round(data.imageWidth * workAreaDpi / data.imageDpi);
        let imageHeight = Math.round(data.imageHeight * workAreaDpi / data.imageDpi);
        let zoomAreaDpi = data.imageDpi;
        let zoomRatio = zoomAreaDpi / workAreaDpi;
        let zoomAreaWidth = workAreaWidth * zoomRatio;
        let zoomAreaHeight = workAreaHeight * zoomRatio;

        this.setState({
            data: data,
            workAreaWidth: workAreaWidth,
            workAreaHeight: workAreaHeight,
            workAreaDpi: workAreaDpi,
            imageWidth: imageWidth,
            imageHeight: imageHeight,
            zoomAreaWidth: zoomAreaWidth,
            zoomAreaHeight: zoomAreaHeight,
            zoomRatio: zoomRatio,
            zoomAreaDpi: zoomAreaDpi,
            isLoading: false
        });
    }

    //Get offset of DOM element from top of document
    getOffsetfromTopOfDocument = (element) => {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    //Get offset of DOM element from left of document
    getOffsetfromLeftOfDocument = (element) => {
        let offsetLeft = 0;
        while (element) {
            offsetLeft += element.offsetLeft;
            element = element.offsetParent;
        }
        return offsetLeft;
    }

    //Center the image in the work area
    centerImage = () => {
        let workArea = document.getElementById(`workArea-${this.props.componentIndex}`);
        let image = document.getElementById(`image-${this.props.componentIndex}`);
        let workAreaWidthHalf = workArea.clientWidth / 2;
        let workAreaHeightHalf = workArea.clientHeight / 2;
        let imageWidthHalf = image.clientWidth / 2;
        let imageHeightHalf = image.clientHeight / 2;
        let originX = (workArea.clientWidth - Math.round(this.state.workAreaDpi * this.state.data.trimWidth)) / 2;
        let originY = (workArea.clientHeight - Math.round(this.state.workAreaDpi * this.state.data.trimHeight)) / 2;
        if (this.state.data.trimBoxOffset.x === null || this.state.data.trimBoxOffset.y === null) {
            this.moveImage(image, workAreaWidthHalf - imageWidthHalf, workAreaHeightHalf - imageHeightHalf);
        }
        else {
            this.moveImage(image, (this.state.data.trimBoxOffset.x * this.state.workAreaDpi) + originX,
                (this.state.data.trimBoxOffset.y * this.state.workAreaDpi) + originY);
        }
    }

    onMouseDown = (event) => {
        if (!this.state.dragging) {
            let image = document.getElementById(`image-${this.props.componentIndex}`);
            this.setState({
                dragging: true,
                mouseDownX: event.clientX,
                mouseDownY: event.clientY,
                baselineX: this.getValueOfPixels(image.style.left),
                baselineY: this.getValueOfPixels(image.style.top),
            });
        }
    }

    onMouseMove = (event) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let followCursor = component.followCursor !== undefined ? component.followCursor : this.props.currentSubmission.followCursor;
        if (followCursor) {
            let x = event.nativeEvent.offsetX;
            let y = event.nativeEvent.offsetY;
            let cursorBox = document.getElementById(`cursor-box-${this.props.componentIndex}`);

            let leftEdge = x - parseInt(cursorBox.style.width, 10) / 2;
            let rightEdge = x + parseInt(cursorBox.style.width, 10) / 2;
            let topEdge = y - parseInt(cursorBox.style.height, 10) / 2;
            let bottomEdge = y + parseInt(cursorBox.style.height, 10) / 2;

            if (leftEdge > 0 && rightEdge < event.target.clientWidth && topEdge > 0 && bottomEdge < event.target.clientHeight) {
                cursorBox.style.left = `${leftEdge}px`;
                cursorBox.style.top = `${topEdge}px`;
                this.props.currentSubmission.ticket.components[this.props.componentIndex].cursorBoxPosition = { left: leftEdge, top: topEdge, dpi: this.state.workAreaDpi };
            }
            this.updateZoomLocation();
        }
        if (this.state.dragging) {
            let image = document.getElementById(`image-${this.props.componentIndex}`);
            let nextX = this.state.baselineX + event.clientX - this.state.mouseDownX;
            let nextY = this.state.baselineY + event.clientY - this.state.mouseDownY;
            this.moveImage(image, nextX, nextY);
        }
    }

    //Move Image and Zoom Image
    moveImage = (image, x, y) => {
        image.style.left = `${x}px`;
        image.style.top = `${y}px`;

        let canvas = document.getElementById(`top-lines-${this.props.componentIndex}`);
        let workAreaWidth = canvas.clientWidth;
        let workAreaHeight = canvas.clientHeight;

        let originX = (workAreaWidth - Math.round(this.state.workAreaDpi * this.state.data.trimWidth)) / 2;
        let originY = (workAreaHeight - Math.round(this.state.workAreaDpi * this.state.data.trimHeight)) / 2;
        this.updateOffset(x - originX, y - originY);

        //Update Zoom image location
        let zoomImage = document.getElementById(`zoom-image-${this.props.componentIndex}`);
        zoomImage.style.left = x * this.state.zoomRatio + 'px';
        zoomImage.style.top = y * this.state.zoomRatio + 'px';
    }

    //Update offset of image
    updateOffset = (x, y) => {
        let currentSubmission = this.props.currentSubmission;
        if (this.state.workAreaDpi > 0) {
            x = (x / this.state.workAreaDpi).toFixed(3);
            y = (y / this.state.workAreaDpi).toFixed(3);
        }
        currentSubmission.ticket.components[this.props.componentIndex].startingOffset = { x, y };
        this.props.updateSubmission(currentSubmission);
    }

    //Helper function that removes the 'px' from values
    getValueOfPixels = (text) => {
        return +text.substring(0, text.indexOf('p'));
    }

    //Double-Click event handler
    doubleClick = (event) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let followCursor = component.followCursor !== undefined ? component.followCursor : this.props.currentSubmission.followCursor;
        if (followCursor) {
            this.updateCheckbox('followCursor', false);
        }
        let x = event.nativeEvent.offsetX;
        let y = event.nativeEvent.offsetY;
        let cursorBox = document.getElementById(`cursor-box-${this.props.componentIndex}`);

        let leftEdge = x - parseInt(cursorBox.style.width, 10) / 2;
        let rightEdge = x + parseInt(cursorBox.style.width, 10) / 2;
        let topEdge = y - parseInt(cursorBox.style.height, 10) / 2;
        let bottomEdge = y + parseInt(cursorBox.style.height, 10) / 2;

        if (leftEdge > 0 && rightEdge < event.target.clientWidth && topEdge > 0 && bottomEdge < event.target.clientHeight) {
            cursorBox.style.left = `${leftEdge}px`;
            cursorBox.style.top = `${topEdge}px`;
            this.props.currentSubmission.ticket.components[this.props.componentIndex].cursorBoxPosition = { left: leftEdge, top: topEdge, dpi: this.state.workAreaDpi };
        }
        this.updateZoomLocation();
    }

    //After the react component renders draw the canvas lines, shadows, etc.
    componentDidUpdate = () => {
        let canvas = document.getElementById(`top-lines-${this.props.componentIndex}`);
        let grid = document.getElementById(`grid-lines-${this.props.componentIndex}`);

        let zoomCanvas = document.getElementById(`zoom-top-lines-${this.props.componentIndex}`);
        let zoomGrid = document.getElementById(`zoom-grid-lines-${this.props.componentIndex}`);

        if (this.state.invalidated) {
            this.drawPositioner(canvas, grid, this.state.workAreaDpi, false);
            this.drawPositioner(zoomCanvas, zoomGrid, this.state.zoomAreaDpi, true);
            if (!this.state.firstDraw) {
                this.setState({ invalidated: false });
            }
        }

        if (this.state.data && this.state.firstDraw) {
            let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
            if (component.startingOffset !== undefined) {
                let image = document.getElementById(`image-${this.props.componentIndex}`);
                let workAreaWidth = canvas.clientWidth;
                let workAreaHeight = canvas.clientHeight;
                let originX = (workAreaWidth - Math.round(this.state.workAreaDpi * this.state.data.trimWidth)) / 2;
                let originY = (workAreaHeight - Math.round(this.state.workAreaDpi * this.state.data.trimHeight)) / 2;
                let xLoc = originX + component.startingOffset.x * this.state.workAreaDpi;
                let yLoc = originY + component.startingOffset.y * this.state.workAreaDpi;
                this.moveImage(image, xLoc, yLoc);
            }
            else {
                this.centerImage();
            }
            if (component.cursorBoxPosition !== undefined) {
                let dpi = component.cursorBoxPosition.dpi;
                let top = component.cursorBoxPosition.top;
                let left = component.cursorBoxPosition.left;
                let newTop = this.state.workAreaDpi * top / dpi;
                let newLeft = this.state.workAreaDpi * left / dpi;
                let cursorBox = document.getElementById(`cursor-box-${this.props.componentIndex}`);
                cursorBox.style.left = `${newLeft}px`;
                cursorBox.style.top = `${newTop}px`;
                this.props.currentSubmission.ticket.components[this.props.componentIndex].cursorBoxPosition = { left: newLeft, top: newTop, dpi: this.state.workAreaDpi };
                this.updateZoomLocation();
            }
            this.setState({ firstDraw: false, invalidated: false });
        }
    }

    //function draws the positioner state on a canvas; uses a DPI for scaling, and if isZoom = true, then show sub-marks if checkbox is checked.
    drawPositioner = (canvas, grid, dpi, isZoom) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let showRuler = component.showRuler !== undefined ? component.showRuler : this.props.currentSubmission.showRuler;
        let showCrosshairs = component.showCrosshairs !== undefined ? component.showCrosshairs :this.props.currentSubmission.showCrosshairs;
        if (this.state.data) {
            let topLinesContext = canvas.getContext('2d');
            let gridContext = grid.getContext('2d');

            gridContext.clearRect(0, 0, grid.clientWidth, grid.clientHeight);
            topLinesContext.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);

            if (showRuler) {
                this.drawGridRuler(gridContext, grid.clientWidth, grid.clientHeight, dpi, isZoom);
            }
            this.drawGhostAreasAndAdSizeOutlines(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
            if (showCrosshairs) {
                this.drawCrossHairs(topLinesContext, canvas.clientWidth, canvas.clientHeight);
            }
            if (component.showBleedAndSafetyLines !== undefined ? component.showBleedAndSafetyLines : true) {
                this.drawFoldLines(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
                this.drawBleed(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
                this.drawSafeties(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
            }
            if (this.state.data.allowGutter && (component.showGutter !== undefined ? component.showGutter : true)) {
                this.drawGutters(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
            }
            if (this.state.data.overlays !== undefined && this.state.data.overlays.length > 0) {
                this.drawOverlays(topLinesContext, canvas.clientWidth, canvas.clientHeight, dpi);
            }
        }
    }

    //Draw safety lines on a canvas
    drawSafeties = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        if (this.state.data && this.state.data.trimElementWidths.length > 0 &&
            this.state.data.elements.length === this.state.data.trimElementWidths.length) {
            let x = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            let y = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;
            canvasContext.strokeStyle = '#0F0';  //green
            for (let i = 0; i < this.state.data.trimElementWidths.length; i++) {
                let safetyTop = (this.state.data.elements[i].safetyTop === null ? this.state.data.safetyTop : this.state.data.elements[i].safetyTop) * dpi;
                let safetyLeft = (this.state.data.elements[i].safetyLeft === null ? this.state.data.safetyLeft : this.state.data.elements[i].safetyLeft) * dpi;
                let safetyRight = (this.state.data.elements[i].safetyRight === null ? this.state.data.safetyRight : this.state.data.elements[i].safetyRight) * dpi;
                let safetyBottom = (this.state.data.elements[i].safetyBottom === null ? this.state.data.safetyBottom : this.state.data.elements[i].safetyBottom) * dpi;
                canvasContext.strokeRect(x + safetyLeft,
                    y + safetyTop,
                    (this.state.data.trimElementWidths[i] * dpi) - safetyLeft - safetyRight, canvasHeight - (y * 2) - safetyTop - safetyBottom);
                x += this.state.data.trimElementWidths[i] * dpi;
            }
        }
    }

    //Draw gutters between safeties
    drawGutters = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        if (this.state.data && this.state.data.trimElementWidths.length > 1 &&
            this.state.data.elements.length === this.state.data.trimElementWidths.length) {
            let x = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            let y = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;
            canvasContext.fillStyle = 'rgb(255,0,0,0.33)';  //transparent red
            for (let i = 0; i < this.state.data.trimElementWidths.length - 1; i++) {
                let safetyLeft = (this.state.data.elements[i + 1].safetyLeft === null ? this.state.data.safetyLeft : this.state.data.elements[i + 1].safetyLeft) * dpi;
                let safetyRight = (this.state.data.elements[i].safetyRight === null ? this.state.data.safetyRight : this.state.data.elements[i].safetyRight) * dpi;
                x += (this.state.data.trimElementWidths[i] * dpi);
                canvasContext.fillRect(x - safetyRight, y,
                    safetyLeft + safetyRight, canvasHeight - (y * 2));
            }
        }
    }

    //Draw custom overlays
    drawOverlays = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let startX = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
        let startY = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;

        for (let i = 0; i < this.state.data.overlays.length; i++) {
            let overlay = this.state.data.overlays[i];
            let settingName = "showOverlay" + overlay.id;
            if (component[settingName] !== undefined ? component[settingName] : true) {
                let x = startX + (overlay.boxLeft * dpi);
                let y = startY + (overlay.boxTop * dpi);
                let width = overlay.boxWidth * dpi;
                let height = overlay.boxHeight * dpi;
                //TODO: If they want image, probably check for that first and make color an else case?
                if (overlay.color) {
                    //Color is ARGB format
                    let a = ((overlay.color >> 24) & 0xFF) / 255.0;
                    let r = (overlay.color >> 16) & 0xFF;
                    let g = (overlay.color >> 8) & 0xFF;
                    let b = (overlay.color) & 0xFF;
                    canvasContext.fillStyle = `rgb(${r},${g},${b},${a})`;
                }
                else {
                    canvasContext.fillStyle = 'rgb(255,0,0,0.33)';  //transparent red
                }
                canvasContext.fillRect(x, y, width, height);
            }
        }
    }

    //Draw bleed lines on a canvas
    drawBleed = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        let x = 0;
        let y = 0;
        let bleedLeft = 0;
        let bleedRight = 0;
        let bleedTop = 0;
        let bleedBottom = 0;

        if (this.state.data && this.state.data.bleed > 0) {
            x = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            y = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;
            bleedLeft = x - this.state.data.bleedLeft * dpi;
            bleedTop = y - this.state.data.bleedTop * dpi;
            bleedRight = canvasWidth - (x * 2) + (x - bleedLeft) + (this.state.data.bleedRight * dpi);
            bleedBottom = canvasHeight - (y * 2) + (y - bleedTop) + (this.state.data.bleedBottom * dpi);
        }

        canvasContext.strokeStyle = '#F00';  //red
        canvasContext.strokeRect(bleedLeft, bleedTop, bleedRight, bleedBottom);
    }
    //Draw folding lines on a canvas
    drawFoldLines = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        if (this.state.data && this.state.data.trimElementWidths.length > 0) {
            let x = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            canvasContext.strokeStyle = '#00F';  //blue
            for (let i = 0; i < this.state.data.trimElementWidths.length - 1; i++) {
                x += this.state.data.trimElementWidths[i] * dpi;
                canvasContext.beginPath();
                canvasContext.moveTo(x, 0);
                canvasContext.lineTo(x, canvasHeight);
                canvasContext.stroke();
            }
        }
    }

    //Draw ghost/transparency areas and the blue ad size outline if checkbox is checked
    drawGhostAreasAndAdSizeOutlines = (canvasContext, canvasWidth, canvasHeight, dpi) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let showAdSizeOutline = component.showAdSizeOutline !== undefined ? component.showAdSizeOutline : this.props.currentSubmission.showAdSizeOutline;
        let x = 0;
        let y = 0;

        if (this.state.data) {
            x = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            y = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;
        }

        canvasContext.fillStyle = '#FFF';  //white
        canvasContext.globalAlpha = 0.4;
        canvasContext.fillRect(0, 0, canvasWidth, y);  //top area
        canvasContext.fillRect(0, canvasHeight - y, canvasWidth, y);  //bottom area
        canvasContext.fillRect(0, y, x, canvasHeight - (y * 2));  //left area
        canvasContext.fillRect(canvasWidth - x, y, x, canvasHeight - (y * 2));  //right area
        canvasContext.globalAlpha = 1.0;

        //If ad size checkbox is checked, show the blue lines
        if (showAdSizeOutline) {
            canvasContext.strokeStyle = '#00F'; //blue
            canvasContext.beginPath();
            canvasContext.moveTo(0, y);
            canvasContext.lineTo(canvasWidth, y);
            canvasContext.stroke();
            canvasContext.beginPath();
            canvasContext.moveTo(0, canvasHeight - y);
            canvasContext.lineTo(canvasWidth, canvasHeight - y);
            canvasContext.stroke();
            canvasContext.beginPath();
            canvasContext.moveTo(x, 0);
            canvasContext.lineTo(x, canvasHeight);
            canvasContext.stroke();
            canvasContext.beginPath();
            canvasContext.moveTo(canvasWidth - x, 0);
            canvasContext.lineTo(canvasWidth - x, canvasHeight);
            canvasContext.stroke();
        }
    }

    //Draw the black crosshairs across the canvas
    drawCrossHairs = (canvasContext, canvasWidth, canvasHeight) => {
        canvasContext.strokeStyle = '#000';  //black
        canvasContext.beginPath();
        canvasContext.moveTo(canvasWidth / 2, 0);
        canvasContext.lineTo(canvasWidth / 2, canvasHeight);
        canvasContext.stroke();
        canvasContext.beginPath();
        canvasContext.moveTo(0, canvasHeight / 2);
        canvasContext.lineTo(canvasWidth, canvasHeight / 2);
        canvasContext.stroke();
    }

    //Draw the ruler lines and numbers on a canvas, both vertical and horizontal lines
    drawGridRuler = (canvasContext, canvasWidth, canvasHeight, dpi, isZoomArea) => {
        this.drawVerticleLines(canvasContext, canvasWidth, canvasHeight, dpi, isZoomArea);
        this.drawHorizontalLines(canvasContext, canvasWidth, canvasHeight, dpi, isZoomArea);
    }

    //Draw the vertical lines and number values
    drawVerticleLines = (canvasContext, canvasWidth, canvasHeight, dpi, isZoomArea) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let showSubInchMarks = component.showSubInchMarks !== undefined ? component.showSubInchMarks : this.props.currentSubmission.showSubInchMarks;
        if (this.state.data && this.state.workAreaDpi > 0) {

            let unit = 0;
            let xLoc = (canvasWidth - Math.round(dpi * this.state.data.trimWidth)) / 2;
            canvasContext.fillStyle = '#FFF';  //white (for text)
            canvasContext.setLineDash([3, 2]);
            while (xLoc < canvasWidth) {
                canvasContext.strokeStyle = '#FFF';  //white (for dashed lines)
                canvasContext.beginPath();
                canvasContext.moveTo(xLoc, 0);
                canvasContext.fillText(unit, xLoc + dpi * 0.1, dpi * 0.3);
                canvasContext.lineTo(xLoc, canvasHeight);
                canvasContext.stroke();

                //If this is specified as a zoom canvas and the checkbox is checked, show ruler sub-marks.
                if (isZoomArea && showSubInchMarks) {
                    let localXLoc = xLoc;
                    canvasContext.strokeStyle = '#BBB';  //light gray
                    let changeInX = this.state.data.unitPreference !== MILLIMETERS ? dpi * 0.125 : (dpi * 10 / MILLIMETERS_PER_INCH) * 0.125;
                    for (let i = 0; i < 7; i++) {
                        localXLoc += changeInX;
                        canvasContext.beginPath();
                        canvasContext.moveTo(localXLoc, 0);
                        canvasContext.lineTo(localXLoc, canvasHeight);
                        canvasContext.stroke();
                    }
                }
                if (this.state.data.unitPreference === MILLIMETERS) {
                    xLoc += dpi * 10 / MILLIMETERS_PER_INCH  //10 mm in pixels
                }
                else {
                    xLoc += dpi; //inch in pixels
                }
                unit++;
            }
        }
    }

    //Draw the horizontal lines and number values
    drawHorizontalLines = (canvasContext, canvasWidth, canvasHeight, dpi, isZoomArea) => {
        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        let showSubInchMarks = component.showSubInchMarks !== undefined ? component.showSubInchMarks : this.props.currentSubmission.showSubInchMarks;
        if (this.state.data && this.state.workAreaDpi > 0) {
            let unit = 0;
            let yLoc = (canvasHeight - Math.round(dpi * this.state.data.trimHeight)) / 2;
            canvasContext.fillStyle = '#FFF';  //white (for text)
            canvasContext.setLineDash([3, 2]);
            while (yLoc < canvasHeight) {
                canvasContext.beginPath();
                canvasContext.strokeStyle = '#FFF';  //white (for dashed lines)
                canvasContext.moveTo(0, yLoc);
                canvasContext.fillText(unit, dpi * 0.1, yLoc + dpi * 0.3);
                canvasContext.lineTo(canvasWidth, yLoc);
                canvasContext.stroke();

                //If the canvas is specified as a zoom canvas and the checkbox is checked, show ruler sub-marks.
                if (isZoomArea && showSubInchMarks) {
                    let localYLoc = yLoc;
                    canvasContext.strokeStyle = '#BBB';  //light gray
                    let changeInY = this.state.data.unitPreference !== MILLIMETERS ? dpi * 0.125 : (dpi * 10 / MILLIMETERS_PER_INCH) * 0.125;
                    for (let i = 0; i < 7; i++) {
                        localYLoc += changeInY;
                        canvasContext.beginPath();
                        canvasContext.moveTo(0, localYLoc);
                        canvasContext.lineTo(canvasWidth, localYLoc);
                        canvasContext.stroke();
                    }
                }
                if (this.state.data.unitPreference === MILLIMETERS) {
                    yLoc += dpi * 10 / MILLIMETERS_PER_INCH;
                }
                else {
                    yLoc += dpi;
                }
                unit++;
            }
        }
    }

    //Key down event handler
    onKeyDown = (event) => {
        event.preventDefault();
        let image = document.getElementById(`image-${this.props.componentIndex}`);
        switch (event.keyCode) {

            //If the event is an arrow key then nudge the image in the positioner
            case KEY_LEFT:
                this.moveImage(image, this.getValueOfPixels(image.style.left) - this.state.workAreaDpi * NUDGE_DIST, this.getValueOfPixels(image.style.top));
                return false;
            case KEY_RIGHT:
                this.moveImage(image, this.getValueOfPixels(image.style.left) + this.state.workAreaDpi * NUDGE_DIST, this.getValueOfPixels(image.style.top));
                return false;
            case KEY_UP:
                this.moveImage(image, this.getValueOfPixels(image.style.left), this.getValueOfPixels(image.style.top) - this.state.workAreaDpi * NUDGE_DIST);
                return false;
            case KEY_DOWN:
                this.moveImage(image, this.getValueOfPixels(image.style.left), this.getValueOfPixels(image.style.top) + this.state.workAreaDpi * NUDGE_DIST);
                return false;

                //If the cursor area is set to not follow the cursor and a number key is pressed, 
                //move the cursor area to the middle / corners / edges of the trim area.

            case KEY_1:
            case KEY_NUMPAD_1:
                this.moveCursorAreaAlongTrim('left', 'bottom');
                return false;
            case KEY_2:
            case KEY_NUMPAD_2:
                this.moveCursorAreaAlongTrim('center', 'bottom');
                return false;
            case KEY_3:
            case KEY_NUMPAD_3:
                this.moveCursorAreaAlongTrim('right', 'bottom');
                return false;
            case KEY_4:
            case KEY_NUMPAD_4:
                this.moveCursorAreaAlongTrim('left', 'center');
                return false;
            case KEY_5:
            case KEY_NUMPAD_5:
                this.moveCursorAreaAlongTrim('center', 'center');
                return false;
            case KEY_6:
            case KEY_NUMPAD_6:
                this.moveCursorAreaAlongTrim('right', 'center');
                return false;
            case KEY_7:
            case KEY_NUMPAD_7:
                this.moveCursorAreaAlongTrim('left', 'top');
                return false;
            case KEY_8:
            case KEY_NUMPAD_8:
                this.moveCursorAreaAlongTrim('center', 'top');
                return false;
            case KEY_9:
            case KEY_NUMPAD_9:
                this.moveCursorAreaAlongTrim('right', 'top');
                return false;
            default:

        }
    }

    //Updates the work area in the zoom
    updateZoomLocation = () => {
        let workArea = document.getElementById(`workArea-${this.props.componentIndex}`);
        let zoomedWorkArea = document.getElementById(`zoom-workArea-${this.props.componentIndex}`);
        let cursorBox = document.getElementById(`cursor-box-${this.props.componentIndex}`);
        let distanceX = this.getValueOfPixels(cursorBox.style.left) - this.getValueOfPixels(workArea.style.left);
        let distanceY = this.getValueOfPixels(cursorBox.style.top) - this.getValueOfPixels(workArea.style.top);

        distanceX *= this.state.zoomRatio;
        distanceY *= this.state.zoomRatio;

        zoomedWorkArea.style.left = -(distanceX) + 'px';
        zoomedWorkArea.style.top = -(distanceY) + 'px';
    }

    //Based upon the inputs, this moves the cursor box to locations along the ad trim.
    moveCursorAreaAlongTrim = (horizontalLocal, verticalLocal) => {

        let canvas = document.getElementById(`top-lines-${this.props.componentIndex}`);
        let cursorBox = document.getElementById(`cursor-box-${this.props.componentIndex}`);

        let canvasWidth = canvas.clientWidth;
        let canvasHeight = canvas.clientHeight;

        let x = (canvasWidth - Math.round(this.state.workAreaDpi * this.state.data.trimWidth)) / 2;
        let y = (canvasHeight - Math.round(this.state.workAreaDpi * this.state.data.trimHeight)) / 2;

        let trimTop = y - (cursorBox.clientHeight / 2);
        let trimLeft = x - (cursorBox.clientWidth / 2);
        let trimRight = canvasWidth - x - (cursorBox.clientWidth / 2);
        let trimBottom = canvasHeight - y - (cursorBox.clientHeight / 2);

        switch (horizontalLocal) {
            case 'left':
                x = trimLeft;
                break;
            case 'center':
                x = canvas.clientWidth / 2 - (cursorBox.clientWidth / 2);
                break;
            case 'right':
                x = trimRight;
                break;
            default:

        }

        switch (verticalLocal) {
            case 'top':
                y = trimTop;
                break;
            case 'center':
                y = canvas.clientHeight / 2 - (cursorBox.clientHeight / 2);
                break;
            case 'bottom':
                y = trimBottom;
                break;
            default:

        }
        cursorBox.style.left = `${x}px`;
        cursorBox.style.top = `${y}px`;
        this.props.currentSubmission.ticket.components[this.props.componentIndex].cursorBoxPosition = { left: x, top: y, dpi: this.state.workAreaDpi };
        this.updateZoomLocation();
    }

    //Format the offset values (x and y) for inches or millimeters for display purposes
    formatOffset = (offset) => {
        if (offset === undefined || offset.x === null || offset.y === null) {
            return '0, 0';
        }
        if (this.state.data && this.state.data.unitPreference === MILLIMETERS) {
            return `${(offset.x * MILLIMETERS_PER_INCH).toFixed(3)} mm, ${(offset.y * MILLIMETERS_PER_INCH).toFixed(3)} mm`;
        }
        return `${(+offset.x).toFixed(3)}", ${(+offset.y).toFixed(3)}"`;
    }

    //Return to Upload/Create a new file
    selectNewFile = () => {
        this.returnToPreviousStatus(TicketComponentStatus.ReadyToUpload, false);
    }

    //Proceed to Preflight with the current offset value
    proceedToPreflight = () => {

        let component = this.props.currentSubmission.ticket.components[this.props.componentIndex];
        fetch(`SvrTicket/SubmitPositioner?tcid=${component.id}&offsetX=${component.startingOffset.x}&offsetY=${component.startingOffset.y}`,
            {
                method: 'POST',
                credentials: "same-origin"
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.ticketComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                    this.closeDialogIfInsideDialog();
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(data.error) });
                    }
                }
            });
    }

    //Update the ticket component to a previous status.  (may remove files and reports)
    returnToPreviousStatus = (prevStatus, autoPosition) => {
        let tcid = this.props.currentSubmission.ticket.components[this.props.componentIndex].id;
        fetch(`SvrTicket/ReturnToStatus?tcid=${tcid}&statusId=${prevStatus}&autoPositionFile=${autoPosition}`, { method: "POST", credentials: 'same-origin' })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.props.currentSubmission.ticket.components[this.props.componentIndex] = data.updatedComponent;
                    this.props.updateSubmission(this.props.currentSubmission);
                    this.closeDialogIfInsideDialog();
                }
                else {
                    if (data.error === 'isDeleted') {
                        this.setState({ ticketState: TicketState.Deleted });
                    }
                    else if (data.error === 'isSubmitted') {
                        this.setState({ ticketState: TicketState.Submitted });
                    }
                    else {
                        let error = (data.error) ? data.error : 'unknownError';
                        this.setState({ showDialog: true, dialogType: DialogType.Error, message: lang(error) });
                    }
                }
            });
    }

    //close dialog
    closeDialog = () => {
        this.setState({ showDialog: false });
    }

    updateCheckbox = (name, checked) => {
        this.props.currentSubmission.ticket.components[this.props.componentIndex][name] = checked;
        this.props.updateSubmission(this.props.currentSubmission);
        this.setState({ invalidated: true });
    }

    closeDialogIfInsideDialog = () => {
        if (this.props.closeDialog) {
            this.props.closeDialog();
        }
    }

    render() {
        const { classes, currentSubmission, componentIndex, isExpanded } = this.props;

        let component = this.props.currentSubmission.ticket.components[componentIndex];
        let followCursor = component.followCursor !== undefined ? component.followCursor : currentSubmission.followCursor;
        let showRuler = component.showRuler !== undefined ? component.showRuler : currentSubmission.showRuler;
        let showSubInchMarks = component.showSubInchMarks !== undefined ? component.showSubInchMarks : currentSubmission.showSubInchMarks;
        let showTransparency = component.transparencyMode !== undefined ? component.transparencyMode : currentSubmission.transparencyMode;
        let showCrosshairs = component.showCrosshairs !== undefined ? component.showCrosshairs : currentSubmission.showCrosshairs;
        let showAdSizeOutline = component.showAdSizeOutline !== undefined ? component.showAdSizeOutline : currentSubmission.showAdSizeOutline;
        let imageTransparency = showTransparency ? 0.6 : 1.0;
        let showBleedAndSafetyLines = component.showBleedAndSafetyLines !== undefined ? component.showBleedAndSafetyLines : true;
        let showGutter = component.showGutter !== undefined ? component.showGutter : true;

        let workAreaWidth = this.state.workAreaWidth;
        let workAreaHeight = this.state.workAreaHeight;
        let imageWidth = this.state.imageWidth;
        let imageHeight = this.state.imageHeight;
        let offset = component.startingOffset !== undefined ? component.startingOffset : (this.state.data ? this.state.data.startingOffset : { x: 0, y: 0 });
        let maxBleed = this.state.data ? this.state.data.unitPreference === MILLIMETERS ? `${this.state.data.bleed * MILLIMETERS_PER_INCH} mm` : `${this.state.data.bleed}"` : 0;
        let showSubMarks = this.state.data ? this.state.data.unitPreference === MILLIMETERS ? lang('showMillimeterMarks') : lang('showInchMarks') : '';
        let cursorBoxWidth = this.state.data ? ZOOM_AREA_WIDTH * this.state.workAreaDpi / this.state.data.imageDpi : 0;
        let zoomAreaWidth = this.state.zoomAreaWidth;
        let zoomAreaHeight = this.state.zoomAreaHeight;
        let zoomImageWidth = this.state.data ? this.state.data.imageWidth : 0;
        let zoomImageHeight = this.state.data ? this.state.data.imageHeight : 0;

        let newFileButtonText = this.props.currentSubmission.ticket.isCreateAnAd ? lang('createNewFile') : lang('selectNewFile')

        let expandText = isExpanded
            ? <div className={classes.expandText}>{`${lang('clickThe')} `}<OpenInNew style={{ transform: 'rotate(180deg)', fontSize: '1em', verticalAlign: 'sub' }} />{` ${lang('positionerMinimizeText')}`}</div>
            : <div className={classes.expandText}>{`${lang('clickThe')} `}<OpenInNew style={{ fontSize: '1em', verticalAlign: 'sub' }} />{` ${lang('positionerExpandText')}`}</div>

        var gutterOption = <React.Fragment />;
        if (this.state.data && this.state.data.allowGutter && this.state.data.trimElementWidths.length > 1) {
            gutterOption = <div><FormControlLabel label={<Typography variant="body2">{lang('showGutter')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showGutter} onChange={(e, checked) => { this.updateCheckbox('showGutter', checked) }} color='primary' />} /></div>;
        }
        var overlayOptions = <React.Fragment />;
        if (this.state.data && this.state.data.overlays && this.state.data.overlays.length > 0) {
            overlayOptions = this.state.data.overlays.map(overlay => {
                let settingName = "showOverlay" + overlay.id;
                let showOverlay = component[settingName] !== undefined ? component[settingName] : true;
                return (<div key={settingName}><FormControlLabel label={<Typography variant="body2">{lang('show')} {overlay.name}</Typography>} control={<Checkbox className={classes.checkbox} checked={showOverlay} onChange={(e, checked) => { this.updateCheckbox(settingName, checked) }} color='primary' />} /></div>)
            });
        }

        return (
            <div style={{ position: 'relative' }} className={classes.positioner}>
                {this.state.isLoading ? (
                    <div className={classes.loadingArea}>
                        <div className={classes.loadingSpinny}>
                            <CircularProgress size={LOADING_SPINNY_SIZE} />
                        </div>
                    </div>
                ) : ''}
                <div id={`mainArea-${componentIndex}`} className={classes.mainArea}>
                    <div id={`workArea-container-${componentIndex}`}>
                        <div id={`workArea-${componentIndex}`} style={{ width: workAreaWidth, height: workAreaHeight }} onDoubleClick={this.doubleClick} className={classes.workArea} onMouseMove={this.onMouseMove} onMouseDown={this.onMouseDown} onMouseUp={() => { if (this.state.dragging) { this.setState({ dragging: false }); } }}>
                            <canvas className={classes.gridLines} id={`grid-lines-${componentIndex}`} style={{ position: 'absolute' }} width={workAreaWidth} height={workAreaHeight}></canvas>
                            <img id={`image-${componentIndex}`} style={{ opacity: imageTransparency, width: imageWidth, height: imageHeight }} className={classes.image} src={`./File/StreamFile?fileType=OriginalFull&id=${currentSubmission.ticket.components[componentIndex].id}&lastModified=${currentSubmission.ticket.components[componentIndex].lastChangedTimestamp}`} alt='' />
                            <canvas className={classes.topLines} id={`top-lines-${componentIndex}`} width={workAreaWidth} height={workAreaHeight}></canvas>
                            <div id={`cursor-box-${componentIndex}`} className={classes.cursorBox} style={{ width: cursorBoxWidth, height: cursorBoxWidth }} />
                            <div id={`cursor-mask-${componentIndex}`} className={classes.cursorMask} onKeyDown={this.onKeyDown} tabIndex='0' />
                        </div>
                    </div>
                    <div className={classes.toolArea}>
                        <Typography component='div' className={classes.bold}>{lang('zoomTool')}</Typography>
                        <Typography component='div' className={classes.infoText}>{lang('toPositionYourImage')}</Typography>
                        <div id={`zoom-area-${componentIndex}`} className={classes.zoomTool}>
                            <div id={`zoom-workArea-${componentIndex}`} style={{ width: zoomAreaWidth, height: zoomAreaHeight }} className={classes.workArea}>
                                <canvas className={classes.gridLines} id={`zoom-grid-lines-${componentIndex}`} style={{ position: 'absolute' }} width={zoomAreaWidth} height={zoomAreaHeight}></canvas>
                                <img id={`zoom-image-${componentIndex}`} style={{ opacity: imageTransparency, width: zoomImageWidth, height: zoomImageHeight }} className={classes.image} src={`./File/StreamFile?fileType=OriginalFull&id=${currentSubmission.ticket.components[componentIndex].id}&lastModified=${currentSubmission.ticket.components[componentIndex].lastChangedTimestamp}`} alt='' />
                                <canvas className={classes.topLines} id={`zoom-top-lines-${componentIndex}`} width={zoomAreaWidth} height={zoomAreaHeight}></canvas>
                            </div>
                        </div>
                        <Typography variant='body2' component='div' className={classes.bold}>{lang('colorLegend')}</Typography>
                        <Typography variant='body2' component='div'>
                            <ul className={classes.list}>
                                <li className={classes.redHighlight}>{langFormat('redLines', maxBleed)}</li>
                                <li className={classes.blueHighlight}>{lang('blueLines')}</li>
                                <li className={classes.greenHighlight}>{lang('greenLines')}</li>
                            </ul>
                        </Typography>
                        <Typography variant='body2' component='div'>{expandText}</Typography>
                    </div>
                    <div className={classes.controlArea}>
                        <Typography variant='body2' className={classes.bold} component='div'>
                            {lang('options')}
                            <div><FormControlLabel label={<Typography variant="body2">{lang('followCursor')}</Typography>} control={<Checkbox className={classes.checkbox} color='primary' checked={followCursor} onChange={(e, checked) => { this.updateCheckbox('followCursor', checked) }} />} /></div>
                            <Typography variant="body2" className={classes.doubleClickInfo} component='div'>{lang('doubleClickAnchorToDisableCursor')}</Typography>
                            <div><FormControlLabel label={<Typography variant="body2">{lang('showRuler')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showRuler} onChange={(e, checked) => { this.updateCheckbox('showRuler', checked) }} color='primary' />} /></div>
                            <div><FormControlLabel className={classes.indent} disabled={!showRuler} label={<Typography variant="body2">{showSubMarks}</Typography>} control={<Checkbox className={classes.checkbox} checked={showSubInchMarks} onChange={(e, checked) => { this.updateCheckbox('showSubInchMarks', checked) }} color='primary' />} /></div>
                            <div><FormControlLabel label={<Typography variant="body2">{lang('transparencyMode')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showTransparency} onChange={(e, checked) => { this.updateCheckbox('transparencyMode', checked) }} color='primary' />} /></div>
                            <div><FormControlLabel label={<Typography variant="body2">{lang('showCrosshairs')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showCrosshairs} onChange={(e, checked) => { this.updateCheckbox('showCrosshairs', checked) }} color='primary' />} /></div>
                            <div><FormControlLabel label={<Typography variant="body2">{lang('showAdSize')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showAdSizeOutline} onChange={(e, checked) => { this.updateCheckbox('showAdSizeOutline', checked) }} color='primary' />} /></div>
                            <div><FormControlLabel label={<Typography variant="body2">{lang('showBleedAndSafety')}</Typography>} control={<Checkbox className={classes.checkbox} checked={showBleedAndSafetyLines} onChange={(e, checked) => { this.updateCheckbox('showBleedAndSafetyLines', checked) }} color='primary' />} /></div>
                            {gutterOption}
                            {overlayOptions}
                        </Typography>
                        <Typography variant="body2" className={classes.offsetHeader} component='div'>{lang('offset')}</Typography>
                        <Typography variant="body2" className={classes.offset} component='div'>{this.formatOffset(offset)}</Typography>
                        <div className={classes.centerImageButton}>
                            <Button onClick={this.centerImage} className={classes.neutralColor} variant='contained'>{lang('centerImage')}</Button>
                        </div>
                        {React.createElement("div", { dangerouslySetInnerHTML: { __html: lang('positionerCustomDisclaimer') } })}
                    </div>
                </div>
                <div className={classes.buttons}>
                    <Button onClick={this.selectNewFile} color='primary' variant='contained'>{newFileButtonText}</Button>
                    <Button onClick={this.proceedToPreflight} color='secondary' autoFocus variant='contained'>{lang('proceedToPreflight')}</Button>
                </div>
                <Dialog open={this.state.showDialog} onClose={this.closeDialog}>
                    <DialogTitle>{lang('error')}</DialogTitle>
                    <DialogContent>{this.state.message}</DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} autoFocus variant='contained' color='secondary'>{lang('ok')}</Button>
                    </DialogActions>
                </Dialog>
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </div>
            );
    }
}

export default withStyles(Positioner, styles);
import React, { Component } from 'react';
import { lang, langFormat } from '../../Language';
import { ProofIncluded } from '../../ProofIncluded';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import TicketDetailsCustom from './TicketDetailsCustom';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Collapse from '@mui/material/Collapse';
import ImageDisplay from '../Common/ImageDisplay';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import 'ie-array-find-polyfill';
import TicketClosedDialog, { TicketState } from './TicketClosedDialog';

const styles = theme => ({
    spacer: {
        paddingTop: theme.spacing(2),
    },
    alignRight: {
        width: '100%',
        textAlign: 'right',
    },
    fakeLabel: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        fontSize: '0.75rem',
        width: '100%',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingTop: theme.spacing(1),
    },
    fakeInput: {
        fontSize: '1rem',
        width: '100%',
    },
    fakeContainer: {
        background: 'rgba(0,0,0,0.09)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        padding: '12px',
        paddingTop: '0',
        marginTop: '4px', //The fakeInput should actually have a marginBottom, but things look too spaced if I do that.
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        '&:hover': {
            background: 'rgba(0,0,0,0.13)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
        },
        'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    groupHeader: {
        marginTop: theme.spacing(1),
        cursor: 'pointer',
        userSelect: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#CCC',
        background: "#EEE",
        display: 'flex',
        height: '26px', //Forces the group header heights to be consistent. Otherwise they can differ by 1px due to rounding
        '&:hover': {
            background: '#D8D8D8',
        },
        '&> div:first-of-type': {
            flex: '1 0 auto',
        },
        '& p': {
            lineHeight: '22px',
        },
        '& button': {
            padding: '1px',
        },
        'transition': 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
    sizePanels: {
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    linkButton: {
        textDecoration: 'underline',
        padding: '3px 4px',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    editButton: {
        alignSelf: 'baseline',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
    notesMargin: {
        marginTop: theme.spacing(1),
    },
    dialogContent: {
        position: 'relative',
    },
    dialogActions: {
        display: 'block',
        justifyContent: 'none',
        textAlign: 'center'
    },
    iframe: {
        width: '100%',
        height: 'calc(100vh - 240px)',
    },
    reportLinks: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 5
    },
    adSpecs: {
        float: 'right'
    }
});

export const FileStatus = Object.freeze({
    Placeholder: 1,
    Waiting: 2,
    Uploading: 3,
    Uploaded: 4,
    Delete: 5,
});

const DialogType = Object.freeze({
    None: 0,
    ProofDeliveryDetails: 1
});

const DialogButtons = Object.freeze({
    OK: 1,
    Cancel: 2,
    Yes: 4,
    No: 8,
});

const DialogReportType = Object.freeze({
    None: 0,
    PrecheckReport: 1,
    PreflightReport: 2
});

const MAX_REFDOC_SIZE = 100 * 1024 * 1024; //100MiB for reference documents.
const MAX_ADVERTISER_NOTES_LENGTH = 254; //One less than 255 because of new line and carriage return.

class EditDetails extends Component {
    displayName = EditDetails.name

    constructor(props) {
        super(props);
        this.state = {
            showingMessage: false,
            lastMessage: '',
            dialogButtons: 1,
            dialogAction: null,
            numRefDocs: 1, //Includes placeholder entry for next upload.
            disableAddFile: false,
            dialogReportType: DialogReportType.None,
            showReportDialog: false,
            componentIdForReportDialog: -1,
            ticketState: TicketState.None
        };
    }

    componentDidMount() {
        if (!this.props.currentSubmission.refDocs) {
            let { currentSubmission } = this.props;
            this.refreshLocalRefDocs(currentSubmission);
            this.props.updateSubmission(currentSubmission);
        }
    }

    componentWillUnmount() {
        let { currentSubmission } = this.props;
        if (currentSubmission.materialDetailsEdit) {
            //They are editing, cancel edit because otherwise they'd still be in edit state the next time this mounts.
            this.materialDetailsEndEdit(false);
        }
        if (currentSubmission.contactDetailsEdit) {
            this.contactDetailsEndEdit(false);
        }
    };

    isTicketResubmission = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission.ticket.originalTicketId) {
            return true;
        }
        return false;
    }
    
    materialDetailsEndEdit = (submit, e) => {
        let { currentSubmission } = this.props;
        if (submit) {
            var form = document.getElementById("materialDetailsForm");
            if (!form.checkValidity()) {
                if (currentSubmission.adDetailsCollapsed) {
                    currentSubmission.adDetailsCollapsed = false;
                    this.props.updateSubmission(currentSubmission);
                }
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();


            const getNumOfNewLineChars = () => {
                let numOfNewLineChars = 0;
                for (let i = 0; i < currentSubmission.ticket.notes.length; i++) {
                    if (currentSubmission.ticket.notes[i] === '\n') {
                        numOfNewLineChars += 2;
                    }
                }
                return numOfNewLineChars;
            }

            if (this.isTicketResubmission()) {
                if (currentSubmission.ticket.isResubmissionNoteRequired && currentSubmission.ticket.notes.trim() === '') {
                    currentSubmission.ticket.notes = currentSubmission.backup.notes; //put old notes back if they tried removing everything.
                }
                else {
                    let numOfNewLineChars = getNumOfNewLineChars()
                    currentSubmission.ticket.notes = currentSubmission.ticket.requiredNotesMessage + '\n' + currentSubmission.ticket.notes;
                    currentSubmission.ticket.notes = currentSubmission.ticket.notes.substring(0, MAX_ADVERTISER_NOTES_LENGTH - numOfNewLineChars); //make sure notes are within field size range.
                }
            }
            else {
                let numOfNewLineChars = getNumOfNewLineChars();
                currentSubmission.ticket.notes = currentSubmission.ticket.notes.substring(0, MAX_ADVERTISER_NOTES_LENGTH - numOfNewLineChars); //make sure notes are within field size range.
            }

            //Create a copy of ticket without components and refDocs because we don't need to send those.
            let baseTicket = Object.keys(currentSubmission.ticket).filter(key => key !== 'components' && key !== 'refDocs').reduce((obj, key) => {
                obj[key] = currentSubmission.ticket[key]; return obj;
            }, {});
            currentSubmission.savingTicketInfo = true;
            this.props.updateSubmission(currentSubmission);
            fetch("SvrTicket/EditMaterialDetails", {
                method: "POST",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin',
                body: JSON.stringify(baseTicket)
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.success) {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                        }
                        else {
                            this.props.showBackgroundDialog(lang(data.error));
                        }
                    }
                    let { currentSubmission } = this.props;
                    currentSubmission.savingTicketInfo = false;
                    this.props.updateSubmission(currentSubmission);
                });
        }
        else if (currentSubmission.ticket) {
            currentSubmission.ticket.advertiser = currentSubmission.backup.advertiser;
            currentSubmission.ticket.notes = currentSubmission.backup.notes;
            currentSubmission.ticket.adId = currentSubmission.backup.adId;
            currentSubmission.ticket.headline = currentSubmission.backup.headline;
            currentSubmission.ticket.description = currentSubmission.backup.description;
            currentSubmission.ticket.product = currentSubmission.backup.product;
            currentSubmission.ticket.companyName = currentSubmission.backup.companyName;
            currentSubmission.ticket.title = currentSubmission.backup.title;
            currentSubmission.ticket.tags = currentSubmission.backup.tags;
            currentSubmission.ticket.urls = currentSubmission.backup.urls;
        }
        currentSubmission.materialDetailsEdit = false;
        this.props.updateSubmission(currentSubmission);
    }

    contactDetailsEndEdit = (submit, e) => {
        let { currentSubmission } = this.props;
        if (submit) {
            var form = document.getElementById("contactDetailsForm");
            if (!form.checkValidity()) {
                if (currentSubmission.contactDetailsCollapsed) {
                    currentSubmission.contactDetailsCollapsed = false;
                    this.props.updateSubmission(currentSubmission);
                }
                return; //Regular submit will happen, causing built in form validation to work.
            }
            e.preventDefault();

            //Create a copy of ticket without components because we don't need to send those.
            let baseTicket = Object.keys(currentSubmission.ticket).filter(key => key !== 'components' && key !== 'refDocs').reduce((obj, key) => {
                obj[key] = currentSubmission.ticket[key]; return obj;
            }, {});
            currentSubmission.savingTicketInfo = true;
            this.props.updateSubmission(currentSubmission);
            fetch("SvrTicket/EditContactDetails", {
                method: "POST",
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin',
                body: JSON.stringify(baseTicket)
            })
                .then(response => response.json())
                .then(data => {
                    if (!data.success) {
                        if (data.error === 'isDeleted') {
                            this.setState({ ticketState: TicketState.Deleted });
                            let { currentSubmission } = this.props;
                            currentSubmission.savingTicketInfo = false;
                            this.props.updateSubmission(currentSubmission);
                        }
                        else if (data.error === 'isSubmitted') {
                            this.setState({ ticketState: TicketState.Submitted });
                            let { currentSubmission } = this.props;
                            currentSubmission.savingTicketInfo = false;
                            this.props.updateSubmission(currentSubmission);
                        }
                        else {
                            let { currentSubmission } = this.props;
                            currentSubmission.savingTicketInfo = false;
                            this.props.updateSubmission(currentSubmission);
                            this.props.showBackgroundDialog(lang(data.error));
                        }
                    }
                    else {
                        this.startNextRefDocUpload();
                    }
                });
        }
        else if (currentSubmission.ticket) {
            currentSubmission.ticket.contactFirstName = currentSubmission.backup.contactFirstName;
            currentSubmission.ticket.contactLastName = currentSubmission.backup.contactLastName;
            currentSubmission.ticket.contactPhone = currentSubmission.backup.contactPhone;
            currentSubmission.ticket.contactEmail = currentSubmission.backup.contactEmail;
            this.refreshLocalRefDocs(currentSubmission);
        }
        currentSubmission.contactDetailsEdit = false;
        this.props.updateSubmission(currentSubmission);
    }

    startNextRefDocUpload = () => {
        let { currentSubmission } = this.props;
        var row = currentSubmission.refDocs.find(item => item.status === FileStatus.Waiting);

        if (row) {
            let data = new FormData();
            let request = new XMLHttpRequest();
            data.append('ticketId', currentSubmission.ticket.id);
            data.append('fileUpload', row.file);

            var outsideThis = this;
            request.addEventListener('load', function (e) {
                outsideThis.onRefDocUploadComplete(request.response, row);
            });
            
            request.upload.addEventListener('progress', function (e) {
                let refDocPercent = (e.loaded / e.total) * 100;
                outsideThis.updateRefDocUploadProgress(row.key, refDocPercent);
            });

            // If server is sending a JSON response then set JSON response type
            request.responseType = 'json';

            // Send POST request to the server side script
            request.open('post', 'SvrTicket/UploadReferenceDoc');
            request.send(data);
            row.status = FileStatus.Uploading;
            row.percent = 0;
            this.props.updateSubmission(currentSubmission);
        }
        else {
            //No files left to upload. Now handle deletes.
            var refDocList = [];
            for (var rIdx in currentSubmission.refDocs) {
                row = currentSubmission.refDocs[rIdx];
                if (row.status === FileStatus.Delete && row.id) {
                    refDocList.push(row.id);
                }
            }
            if (refDocList.length > 0) {
                fetch("SvrTicket/DeleteReferenceDocuments", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({ ticketId: currentSubmission.ticket.id, documentIds: refDocList })
                })
                    .then(response => response.json())
                    .then(data => {
                        let { currentSubmission } = this.props;
                        if (data.success) {
                            this.clearDeletedRefDocs(currentSubmission);
                        }
                        else {
                            this.props.showBackgroundDialog(lang(data.error));
                        }
                        currentSubmission.savingTicketInfo = false;
                        this.props.updateSubmission(currentSubmission);
                    });
            }
            else {
                //Nothing to delete, done saving
                currentSubmission.savingTicketInfo = false;
                this.props.updateSubmission(currentSubmission);
            }
        }
    }

    updateRefDocUploadProgress = (key, percent) => {
        let { currentSubmission } = this.props;
        var row = currentSubmission.refDocs.find(item => item.key === key);
        if (row) {
            row.percent = percent;
            this.props.updateSubmission(currentSubmission);
        }
    }

    clearDeletedRefDocs = (currentSubmission) => {
        let deletedDocs = currentSubmission.refDocs.filter(rd => rd.status === FileStatus.Delete);
        //Function exists to avoid console warning about calling an inline function (array.find argument) inside a loop.
        function safeFind(id) {
            return currentSubmission.ticket.refDocs.find(rd => rd.id === id);
        }
        
        for (var delDoc in deletedDocs) {
            let toDelete = safeFind(deletedDocs[delDoc].id);
            if (toDelete) {
                let delIdx = currentSubmission.ticket.refDocs.indexOf(toDelete);
                if (delIdx >= 0) {
                    currentSubmission.ticket.refDocs.splice(delIdx, 1);
                }
            }
        }
        currentSubmission.refDocs = currentSubmission.refDocs.filter(rd => rd.status !== FileStatus.Delete);
    }

    onRefDocUploadComplete = (data, upload) => {
        let { currentSubmission } = this.props;
        if (!data) {
            //HTTP error, usually caused by going over server HTTP upload limit
            data = { success: false, error: 'maxFileSizeError' };
        }
        if (data.success) {
            var row = currentSubmission.refDocs.find(item => item.key === upload.key);
            row.status = FileStatus.Uploaded;
            row.file = null; //It's uploaded so we don't need it anymore.
            row.id = data.id;
            currentSubmission.ticket.refDocs.push({ id: row.id, fileName: row.fileName });
            this.props.updateSubmission(currentSubmission);
            this.startNextRefDocUpload();
        }
        else {
            currentSubmission.savingTicketInfo = false;
            this.props.updateSubmission(currentSubmission);
            this.props.showBackgroundDialog(lang(data.error));
        }
    }

    invokeEdit = (editing) => {
        let { currentSubmission } = this.props;
        currentSubmission[editing] = true;

        if (editing === 'materialDetailsEdit') {
            currentSubmission.backup.advertiser = currentSubmission.ticket.advertiser;
            currentSubmission.backup.notes = currentSubmission.ticket.notes;
            currentSubmission.backup.adId = currentSubmission.ticket.adId;
            currentSubmission.backup.headline = currentSubmission.ticket.headline;
            currentSubmission.backup.description = currentSubmission.ticket.description;
            currentSubmission.backup.product = currentSubmission.ticket.product;
            currentSubmission.backup.companyName = currentSubmission.ticket.companyName;
            currentSubmission.backup.title = currentSubmission.ticket.title;
            currentSubmission.backup.tags = currentSubmission.ticket.tags;
            currentSubmission.backup.urls = JSON.parse(JSON.stringify(currentSubmission.ticket.urls)); //Deep copy
            currentSubmission.adDetailsCollapsed = false;
            //If the ad is a resubmission remove off the required "Resubmission of Ticket {ID}" text.
            if (this.isTicketResubmission()) {
                if (currentSubmission.ticket.notes.indexOf(currentSubmission.ticket.requiredNotesMessage + '\n') >= 0) {
                    currentSubmission.ticket.notes = currentSubmission.ticket.notes.replace(currentSubmission.ticket.requiredNotesMessage + '\n', '');
                }
                else if (currentSubmission.ticket.notes.indexOf(currentSubmission.ticket.requiredNotesMessage) >= 0) {
                    currentSubmission.ticket.notes = currentSubmission.ticket.notes.replace(currentSubmission.ticket.requiredNotesMessage, '');
                }
            }
        }
        else if (editing === 'contactDetailsEdit') {
            currentSubmission.backup.contactFirstName = currentSubmission.ticket.contactFirstName;
            currentSubmission.backup.contactLastName = currentSubmission.ticket.contactLastName;
            currentSubmission.backup.contactPhone = currentSubmission.ticket.contactPhone;
            currentSubmission.backup.contactEmail = currentSubmission.ticket.contactEmail;
            currentSubmission.contactDetailsCollapsed = false;
            this.refreshLocalRefDocs(currentSubmission);
        }

        this.props.updateSubmission(currentSubmission);
    }

    refreshLocalRefDocs = (currentSubmission) => {
        currentSubmission.refDocs = [];
        if (currentSubmission.ticket.refDocs) {
            for (var rIdx in currentSubmission.ticket.refDocs) {
                var refDoc = currentSubmission.ticket.refDocs[rIdx];
                currentSubmission.refDocs.push({ key: `refDoc${refDoc.id}`, fileName: refDoc.fileName, status: FileStatus.Uploaded, id: refDoc.id });
            }
        }
        const { numRefDocs } = this.state;
        currentSubmission.refDocs.push({ key: `refDocFile${numRefDocs}`, fileName: null, status: FileStatus.Placeholder });
    }

    onEditDetailsClicked = () => {
        this.setState({
            lastMessage: lang("editDetailsWarning"),
            dialogButtons: DialogButtons.Yes | DialogButtons.No,
            dialogAction: () => {
                let { currentSubmission } = this.props;
                currentSubmission.showEditDetails = true;
                currentSubmission.wasDeliveryConfirmationDialogShown = false;
                this.props.updateSubmission(currentSubmission);
            },
            showingMessage: true,
        });
    }

    handleChange = (event) => {
        let { currentSubmission } = this.props;

        if (event.target.type === 'checkbox') {
            currentSubmission.ticket[event.target.value] = event.target.checked;
        }
        else {
            currentSubmission.ticket[event.target.name] = event.target.value;
        }

        this.props.updateSubmission(currentSubmission);
    };

    closeMessage = (dialogResult) => {
        let doAction = null;
        if (this.state.dialogAction && (dialogResult === DialogButtons.OK || dialogResult === DialogButtons.Yes)) {
            doAction = this.state.dialogAction;
        }
        this.setState({ showingMessage: false, dialogAction: null, dialogType: DialogType.None, lastMessage: '' });
        //We save the action until after the dialog is cleared, in case the action wants to show a dialog.
        if (doAction) {
            doAction();
        }
    };

    toggleGroup = (groupName) => {
        let { currentSubmission } = this.props;
        currentSubmission[groupName] = !currentSubmission[groupName];
        this.props.updateSubmission(currentSubmission);
    }

    foldingDummyError = (event) => {
        event.target.style.display = 'none';
    }

    onRefDocAdded = (e) => {
        if (e.target.files.length > 0) {
            let { currentSubmission } = this.props;
            let { numRefDocs } = this.state;
            var row = currentSubmission.refDocs.find(item => item.key === e.target.id);
            if (row) {
                if (e.target.files[0].size > MAX_REFDOC_SIZE) {
                    this.setState({ lastMessage: lang("fileTooLarge"), showingMessage: true, dialogButtons: DialogButtons.OK });
                }
                else {
                    this.setState({ disableAddFile: true });
                    var formData = new FormData();
                    var fileName = e.target.files[0].name;
                    formData.append('ticketId', currentSubmission.ticket.id);
                    formData.append('fileName', fileName);
                    fetch("SvrTicket/ValidateRefDocFilename", {
                        method: "POST",
                        credentials: 'same-origin',
                        body: formData
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success && data.valid) {
                                row.fileName = fileName;
                                row.file = document.getElementById(row.key).files[0];
                                row.status = FileStatus.Waiting;
                                currentSubmission.refDocs.push({ key: `refDocFile${numRefDocs + 1}`, fileName: null, status: FileStatus.Placeholder });
                                this.setState({ disableAddFile: false, numRefDocs: numRefDocs + 1 });
                                this.props.updateSubmission(currentSubmission);
                            }
                            else if (data.valid === false && data.errors) {
                                var message = langFormat('invalidFile') + " " + data.errors.join(" ");
                                //No need to use a background dialog for this, because if they navigate away in the tiny amount of time
                                //it takes to do server side validation their edit is automatically canceled anyway.
                                this.setState({ disableAddFile: false, lastMessage: message, showingMessage: true, dialogButtons: DialogButtons.OK });
                            }
                            else {
                                this.setState({ disableAddFile: false, lastMessage: lang(data.error), showingMessage: true, dialogButtons: DialogButtons.OK });
                            }
                        });
                }
            }
        }
    }

    addRefDocClicked = () => {
        let { currentSubmission } = this.props;
        let placeholderRow = currentSubmission.refDocs.find(item => item.status === FileStatus.Placeholder);
        document.getElementById(placeholderRow.key).click();
    }

    deleteRefDocClicked = (e, key) => {
        let { currentSubmission } = this.props;
        let row = currentSubmission.refDocs.find(item => item.key === key);
        if (row) {
            if (row.status === FileStatus.Uploaded) {
                row.status = FileStatus.Delete;
                this.props.updateSubmission(currentSubmission);
            }
            else {
                let rIdx = currentSubmission.refDocs.indexOf(row);
                currentSubmission.refDocs.splice(rIdx, 1); //Return value is the removed elements, we don't need that.
                this.props.updateSubmission(currentSubmission);
            }
        }
    }

    showDialog = (dialogType, ticketComponentId) => {
        this.setState({ showReportDialog: true, dialogReportType: dialogType, componentIdForReportDialog: ticketComponentId });
    }

    getDisplayColor = (ticket) => {
        let prevColor = null;
        for (var i = 0; i < ticket.components.length; i++) {
            let component = ticket.components[i];
            if (component.isPublisherSupplied) {
                continue;
            }
            let myColor = "";
            if (component.colorCyan) {
                myColor += "C";
            }
            if (component.colorMagenta) {
                myColor += " M";
            }
            if (component.colorYellow) {
                myColor += " Y";
            }
            if (component.colorBlack) {
                myColor += " K";
            }
            if (component.usePantoneColor && component.pantoneColor) {
                if (myColor.length > 0) {
                    myColor += ", ";
                }
                myColor += component.pantoneColor;
            }
            if (prevColor && prevColor !== myColor) {
                prevColor = lang("varied");
            }
            else {
                prevColor = myColor;
            }
        }
        return prevColor || '';
    }

    showSizePanels = (ticket) => {
        if (!ticket.isPrint) {
            return ''; //No size panels
        }
        const { classes } = this.props;
        let foldingDummy = '';
        if (ticket.size && ticket.size.foldingDummyExists) {
            foldingDummy = <img onError={this.foldingDummyError} src={`File/StreamFile?fileType=AdSizePreview&id=${ticket.publisherId},${ticket.sizeId}`} alt='Folding Dummy N/A' />;
        }
        let dispBleed = (ticket.components[0].template && ticket.components[0].template.bleed.length > 0) ? <Typography variant="body2">{lang("bleed")} - {ticket.components[0].template.bleed}</Typography> : '';
        let dispSafety = (ticket.components[0].template && ticket.components[0].template.safety.length > 0) ? <Typography variant="body2">{lang("safety")} - {ticket.components[0].template.safety}</Typography> : '';
        let dispColors = <Typography variant="body2">{lang("colors")} - {this.getDisplayColor(ticket)}</Typography>;
        let dispWidth = lang("seePanels");
        let dispHeight = ticket.components[0].template ? ticket.components[0].template.height : '';
        let showPanelWidths = true;
        if (ticket.components.length === 1 && ticket.components[0].template) {
            dispWidth = ticket.components[0].template.width;
            showPanelWidths = false;
        }
        if (ticket.components.length > 1) {
            var minMaxObj = { min: 9999, max: -9999 };
            for (var i = 0; i < ticket.components.length; i++) {
                var component = ticket.components[i];
                if (!component.template) continue;
                var heightWithUnitOfMeasurement = component.template.height;
                var height = +(component.template.height.replace(component.userMeasurementPreference, "").trim());
                if (height < minMaxObj.min) {
                    minMaxObj.min = height;
                }
                if (height > minMaxObj.max) {
                    minMaxObj.max = height;
                }
            }
            if (minMaxObj.min !== minMaxObj.max) {
                var unitOfMeasurement = ticket.components[0].userMeasurementPreference;
                dispHeight = `${minMaxObj.min} ${unitOfMeasurement} - ${minMaxObj.max} ${unitOfMeasurement}`;
            }
        }

        let links = [];
        ticket.components.map((component, index) => {
            let panelText = '';
            if (component.panelPositions) {
                if (component.panelPositions.length === 1) {
                    if (component.panelPositions[0].name === null) {
                        panelText = `Page ${index + 1}`;
                    }
                    else {
                        panelText = component.panelPositions[0].name;
                    }
                }
                else if (component.panelPositions.length === 2) {
                    panelText = component.panelPositions[0].name + '/' + component.panelPositions[component.panelPositions.length - 1].name;
                }
                else if (component.panelPositions.length > 2) {
                    panelText = component.panelPositions[0].name + '-' + component.panelPositions[component.panelPositions.length - 1].name;
                }
            }
            else if (ticket.IsrUploadPageCount) {
                panelText = `Page ${index + 1}`;
            }

            if (component.precheckReportExists) {
                links.push(<Typography variant="body2" key={`linkPrecheck_${index}`}><RouterLink to='#' onClick={() => { this.showDialog(DialogReportType.PrecheckReport, component.id) }}>{langFormat('viewPrecheckReport', panelText)}</RouterLink></Typography>);
            }
            if (component.preflightReportExists) {
                links.push(<Typography variant="body2" key={`linkPreflight_${index}`}><RouterLink to='#' onClick={() => { this.showDialog(DialogReportType.PreflightReport, component.id) }}>{langFormat('viewPreflightReport', panelText)}</RouterLink></Typography>);
            }
            return '';
        });
        if (links.length > 0) {
            links = <div className={classes.reportLinks}>
                {links.map(link => {
                    return link;
                })}
            </div>;
        }

        return (
            <React.Fragment>
                <div className={classes.sizePanels}>
                    <div style={{ display: 'inline-flex' }}>
                        {foldingDummy}
                        {ticket.components.map(component => {
                            let filePath = '';
                            if (component.isPublisherSupplied) {
                                filePath = `File/StreamPublisherSuppliedImage?tcid=${component.id}`;
                            }
                            else if (component.fileType !== null) {
                                filePath = `File/StreamFile?fileType=${component.fileType}&id=${component.id}&hash=${component.fileHash}`;
                            }
                            else {
                                filePath = '';
                            }
                            return <ImageDisplay key={`img_${component.id}`} filePath={filePath} component={component} templateSize={component.tempSize} marginLeft={10} showPanelWidths={showPanelWidths} />;
                        })}
                        <div style={{ marginLeft: 16, whiteSpace: 'nowrap', display: 'block' }}>
                            <div><Typography variant="body2">{lang('width')} - {dispWidth}</Typography></div>
                            <div><Typography variant="body2">{lang('height')} - {dispHeight}</Typography></div>
                            <div>{dispBleed}</div>
                            <div>{dispSafety}</div>
                            <div>{dispColors}</div>
                        </div>
                    </div>
                </div>
                {links}
            </React.Fragment>
        );
    }

    getRefDocContent = () => {
        const { classes, currentSubmission } = this.props;
        let refDocContent = <div>&nbsp;</div>;
        if (currentSubmission.refDocs) {
            let addLink = '';
            if (currentSubmission.contactDetailsEdit) {
                addLink = <Link component={Button} disabled={this.state.disableAddFile} className={classes.linkButton} onClick={() => this.addRefDocClicked()}>{lang("addReferenceDocument")}</Link>;
            }
            else if (!currentSubmission.refDocs.find(rd => rd.status !== FileStatus.Delete && rd.fileName)) {
                addLink = <span>&nbsp;</span>;
            }
            refDocContent = <div>
                <div id="refDocsTank">
                    {currentSubmission.refDocs.map(refDoc => {
                        if (refDoc.status === FileStatus.Delete) {
                            return ''; //Hide the row until we save.
                        }
                        let line1 = '';
                        let line2 = '';
                        if (refDoc.fileName) {
                            let fileLink = refDoc.fileName;
                            if (refDoc.status === FileStatus.Uploaded && refDoc.id) {
                                fileLink = <a href={`File/StreamFile?fileType=RefDoc&id=${refDoc.id}`} alt={refDoc.fileName} target="_blank" rel="noopener noreferrer">{refDoc.fileName}</a>;
                            }
                            line1 = <Typography variant="body2">
                                <span>{fileLink}</span> {(currentSubmission.contactDetailsEdit) ?
                                    <Link component={Button} className={classes.linkButton} onClick={(e) => this.deleteRefDocClicked(e, refDoc.key)}>{lang("delete")}</Link> : ''
                                }</Typography>;
                        }
                        if (refDoc.status === FileStatus.Uploading) {
                            line2 = <LinearProgress variant="determinate" value={refDoc.percent} />;
                        }
                        return (<React.Fragment key={`refDoc_${refDoc.key}`} >
                            <input type="file" id={refDoc.key} style={{ display: 'none' }} onChange={(e) => this.onRefDocAdded(e)} />
                            {line1}
                            {line2}
                        </React.Fragment>);
                    })}
                </div>
                {addLink}
            </div>;
        }
        return refDocContent;
    }

    generateProofDeliveryAddress = (proofDeliveryAddress) => {
        let addressArray = proofDeliveryAddress.split('<br/>');
        return addressArray.map((x, index) => <React.Fragment key={`pda_${index}`}><div style={{ whiteSpace: 'nowrap' }}>{x}</div></React.Fragment>);
    }

    getDialogContent = () => {
        let buttons = [];
        let activeButtons = this.state.dialogButtons;
        if ((activeButtons & DialogButtons.No) === DialogButtons.No) {
            buttons.push(<Button key="btnNo" onClick={() => this.closeMessage(DialogButtons.No)} variant="contained" color="primary">
                {lang("no")}
            </Button>);
        }
        if ((activeButtons & DialogButtons.Yes) === DialogButtons.Yes) {
            buttons.push(<Button key="btnYes" onClick={() => this.closeMessage(DialogButtons.Yes)} variant="contained" color="secondary" autoFocus>
                {lang("yes")}
            </Button>);
        }
        if ((activeButtons & DialogButtons.Cancel) === DialogButtons.Cancel) {
            buttons.push(<Button key="btnCancel" onClick={() => this.closeMessage(DialogButtons.Cancel)} variant="contained" color="primary">
                {lang("cancel")}
            </Button>);
        }
        if (buttons.length === 0 || (activeButtons & DialogButtons.OK) === DialogButtons.OK) {
            buttons.push(<Button key="btnOK" onClick={() => this.closeMessage(DialogButtons.OK)} variant="contained" color="secondary" autoFocus>
                {lang("ok")}
            </Button>);
        }

        let dialogContent = <DialogContentText id="alert-dialog-description">{this.state.lastMessage}</DialogContentText>;
        let dialogTitle = '';
        if (this.state.dialogType === DialogType.ProofDeliveryDetails) {
            dialogTitle = <DialogTitle>{lang('proofDeliveryDetails')}</DialogTitle>;
            dialogContent = (
                <div>
                    {this.props.currentSubmission.ticket.proofDeliveryRecipient.trim() !== '' ? <Typography variant='body2' style={{ textAlign: 'center', color: '#000' }} component='div'>{this.props.currentSubmission.ticket.proofDeliveryRecipient}</Typography> : ''}
                    {this.props.currentSubmission.ticket.isProofEmailVisible && this.props.currentSubmission.ticket.isrName.trim() !== '' && this.props.currentSubmission.ticket.isrEmailAddress.trim() !== '' ? <Typography variant='body2' style={{ textAlign: 'center', color: '#000' }} component='div'>{`${lang('contact')} `}<a href={`mailto:${this.props.currentSubmission.ticket.isrEmailAddress}`}>{this.props.currentSubmission.ticket.isrName}</a></Typography> : ''}
                    <Typography variant='body2' style={{ textAlign: 'center', color: '#000' }} component='div'>{this.generateProofDeliveryAddress(this.props.currentSubmission.ticket.proofDeliveryAddress)}</Typography>
                </div>
            );
        }


        return <Dialog
            open={this.state.showingMessage}
            onClose={this.closeMessage}
            aria-describedby="alert-dialog-description"
        >
            {dialogTitle}
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions>
                {buttons}
            </DialogActions>
        </Dialog>;
    }

    closeReportDialog = () => {
        this.setState({ showReportDialog: false });
    }

    getReportDialog = () => {
        let dialogContents = '';
        let dialogTitle = '';
        if (this.state.dialogReportType === DialogReportType.PrecheckReport) {
            dialogTitle = lang('precheckReport');
            dialogContents = <iframe className={this.props.classes.iframe} title={this.state.componentIdForReportDialog} src={`./File/StreamFile?fileType=PrecheckReport&id=${this.state.componentIdForReportDialog}`} />
        }
        else if (this.state.dialogReportType === DialogReportType.PreflightReport) {
            dialogTitle = lang('preflightReport');
            dialogContents = <iframe className={this.props.classes.iframe} title={this.state.componentIdForReportDialog} src={`./File/StreamFile?fileType=PreflightReport&id=${this.state.componentIdForReportDialog}`} />
        }

        return (
            <Dialog open={this.state.showReportDialog} fullWidth scroll='body' maxWidth='lg' onClose={this.closeReportDialog} >
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent className={this.props.classes.dialogContent}>
                    {dialogContents}
                </DialogContent>
                <DialogActions className={this.props.classes.dialogActions}>
                    <Button name='ok' variant='contained' autoFocus onClick={this.closeReportDialog} color="secondary">
                        {lang('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
            );
    }

    render() {
        let { currentSubmission } = this.props;
        const { classes } = this.props;
        let submittingTo = [];
        let adMetadata = '';
        let contactInfo = [];
        submittingTo.push(<React.Fragment key="pubIssue">
            <div>
                <div className={classes.fakeLabel}>{lang("publication")}</div>
                <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.publicationName}</Typography>
            </div>
            <div>
                <div className={classes.fakeLabel}>{lang("issue")}</div>
                <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.issueName}</Typography>
            </div>
        </React.Fragment>);

        if (currentSubmission.ticket.isPrint) {
            let adSpecsSection = currentSubmission.ticket.adSpecsUrl.trim() !== '' ? <div className={classes.adSpecsUrlSection}>
                <a className={classes.adSpecs} target='_blank' rel='noopener noreferrer' href={currentSubmission.ticket.adSpecsUrl}>{lang('adSpecs')}</a>
            </div> : ''; 
            let showProofDeliveryDetails = true;
            for (let i = 0; i < currentSubmission.ticket.proofOptions.proofOptions.length; i++) {
                if (currentSubmission.ticket.proofOptions.proofOptions[i].id === ProofIncluded.Virtual) {
                    showProofDeliveryDetails = false;
                    break;
                }
            }
            let proofDeliveryDetails = '';
            if (showProofDeliveryDetails && currentSubmission.ticket.isrEmailAddress && currentSubmission.ticket.isrEmailAddress.trim() !== '' && currentSubmission.ticket.isrName && currentSubmission.ticket.isrName.trim() !== '' &&
                currentSubmission.ticket.proofDeliveryAddress && currentSubmission.ticket.proofDeliveryAddress.trim() !== '') {
                proofDeliveryDetails = <div className={classes.adSpecsUrlSection}><RouterLink to='#' onClick={() => { this.setState({ showingMessage: true, dialogType: DialogType.ProofDeliveryDetails, dialogButtons: DialogButtons.OK }) }} className={classes.adSpecs}>{lang('proofDeliveryDetails')}</RouterLink></div>;
            }

            submittingTo.push(<React.Fragment key="printDetails">
                <div key="adSize">
                    <div className={classes.fakeLabel}>{lang("adSize")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.sizeName}</Typography>
                </div>
                {adSpecsSection}
                <div key="proofOptions">
                    <div className={classes.fakeLabel}>{lang("proofOptions")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{lang(currentSubmission.ticket.proofIncludedName)}</Typography>
                </div>
                {proofDeliveryDetails}
            </React.Fragment>);
        }
        else {
            submittingTo.push(<div key="mediaFileType">
                    <div className={classes.fakeLabel}>{lang("fileType")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.fileTypeName}</Typography>
            </div>);

            if (currentSubmission.ticket.mediaAdSpecsUrl.trim() !== '') {
                submittingTo.push(<div key='adSpecsUrl' className={classes.adSpecsUrlSection}>
                    <a className={classes.adSpecs} target='_blank' rel='noopener noreferrer' href={currentSubmission.ticket.mediaAdSpecsUrl}>{lang('adSpecs')}</a>
                </div>);
            }

            if (currentSubmission.ticket.resolutionId) {
                submittingTo.push(<div key="mediaResolution">
                    <div className={classes.fakeLabel}>{lang("resolution")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.resolutionName}</Typography>
                </div>);
            }
        }
        if (currentSubmission.materialDetailsEdit) {
            adMetadata = <React.Fragment>
                {!this.isTicketResubmission() ?
                    [<React.Fragment key="materialDetailsEditNew">
                        <div>
                            <FormControl key="advertiser" margin="dense" required fullWidth variant="standard">
                                <InputLabel htmlFor="advertiser">{lang("advertiser")}</InputLabel>
                                <Input id="advertiser" name="advertiser" value={currentSubmission.ticket.advertiser} onChange={this.handleChange} inputProps={{ maxLength: "50" }} />
                            </FormControl>
                        </div>
                        <TicketDetailsCustom key="customDetails" currentSubmission={currentSubmission} updateSubmission={this.props.updateSubmission} />
                    </React.Fragment>] :
                    [
                        <React.Fragment key="materialDetailsEditResub">
                            <div>
                                <div className={classes.fakeLabel}>{lang("advertiser")} *</div>
                                <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.advertiser}</Typography>
                            </div>
                            <TicketDetailsCustom key="customDetails" currentSubmission={currentSubmission} readOnly />
                        </React.Fragment>
                    ]
                }
                <FormControl key="notes" className={classes.notesMargin} fullWidth variant="filled">
                    <InputLabel htmlFor="detailsNotes">{lang("notes")}</InputLabel>
                    <FilledInput
                        id="detailsNotes"
                        name="notes"
                        multiline
                        rows={3}
                        value={currentSubmission.ticket.notes}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: "255" }}
                    />
                </FormControl>
            </React.Fragment>;
        }
        else{
            adMetadata = <React.Fragment>
                <div>
                    <div className={classes.fakeLabel}>{lang("advertiser")} *</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.advertiser}</Typography>
                </div>
                <TicketDetailsCustom key="customDetails" currentSubmission={currentSubmission} readOnly />
                <div className={classes.fakeContainer}>
                    <div className={classes.fakeLabel}>{lang("notes")}</div>
                    <Typography variant="body2">{currentSubmission.ticket.notes}</Typography>
                </div>
            </React.Fragment>;
        }

        if (currentSubmission.contactDetailsEdit) {
            contactInfo.push(<React.Fragment key="personalInfo">
                <div>
                    <FormControl margin="dense" fullWidth variant="standard" variant="standard">
                        <InputLabel htmlFor="contactfirstName">{lang("firstName")}</InputLabel>
                        <Input name="contactFirstName" value={currentSubmission.ticket.contactFirstName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                    </FormControl>
                </div>
                <div>
                    <FormControl margin="dense" fullWidth variant="standard" variant="standard">
                        <InputLabel htmlFor="contactlastName">{lang("lastName")}</InputLabel>
                        <Input name="contactLastName" value={currentSubmission.ticket.contactLastName} onChange={this.handleChange} inputProps={{ maxLength: "30" }} />
                    </FormControl>
                </div>
                <div>
                    <FormControl margin="dense" fullWidth variant="standard" variant="standard">
                        <InputLabel htmlFor="contactPhone">{lang("contactPhone")}</InputLabel>
                        <Input name="contactPhone" value={currentSubmission.ticket.contactPhone} onChange={this.handleChange} inputProps={{ maxLength: "15" }} />
                    </FormControl>
                </div>
                <div>
                    <FormControl margin="dense" fullWidth variant="standard" variant="standard">
                        <InputLabel htmlFor="contactEmail">{lang("contactEmail")}</InputLabel>
                        <Input name="contactEmail" type="email" value={currentSubmission.ticket.contactEmail} onChange={this.handleChange} inputProps={{ maxLength: "100" }} />
                    </FormControl>
                </div>
            </React.Fragment>);
        }
        else {
            contactInfo.push(<React.Fragment key="personalInfo">
                <div>
                    <div className={classes.fakeLabel}>{lang("firstName")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.contactFirstName}</Typography>
                </div>
                <div>
                    <div className={classes.fakeLabel}>{lang("lastName")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.contactLastName}</Typography>
                </div>
                <div>
                    <div className={classes.fakeLabel}>{lang("contactPhone")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.contactPhone}</Typography>
                </div>
                <div>
                    <div className={classes.fakeLabel}>{lang("contactEmail")}</div>
                    <Typography variant="body2" className={classes.fakeInput}>{currentSubmission.ticket.contactEmail}</Typography>
                </div>
            </React.Fragment>);
        }

        contactInfo.push(<div key="refDocs" className={classes.fakeContainer}>
            <div className={classes.fakeLabel}>{lang("referenceDocuments")}</div>
            {this.getRefDocContent(currentSubmission)}
        </div>);

        let materialDetailsAction = '';
        let contactAction = '';
        if (!currentSubmission.savingTicketInfo) {
            if (currentSubmission.materialDetailsEdit) {
                materialDetailsAction = <React.Fragment>
                    <Button color="primary" variant="contained" size="small" onClick={() => this.materialDetailsEndEdit(false)}>{lang("cancel")}</Button>
                    <Button type="submit" color="secondary" variant="contained" size="small" onClick={(e) => this.materialDetailsEndEdit(true, e)}>{lang("apply")}</Button>
                </React.Fragment>;
            }
            else {
                materialDetailsAction = <Button className={classes.editButton} variant="text" size="small" onClick={() => this.invokeEdit('materialDetailsEdit')}>{lang("edit")}</Button>;
            }
            if (currentSubmission.contactDetailsEdit) {
                contactAction = <React.Fragment>
                    <Button color="primary" variant="contained" size="small" onClick={() => this.contactDetailsEndEdit(false)}>{lang("cancel")}</Button>
                    <Button type="submit" color="secondary" variant="contained" size="small" onClick={(e) => this.contactDetailsEndEdit(true, e)}>{lang("apply")}</Button>
                </React.Fragment>;
            }
            else if (!this.isTicketResubmission()){
                contactAction = <Button className={classes.editButton} variant="text" size="small" onClick={() => this.invokeEdit('contactDetailsEdit')}>{lang("edit")}</Button>;
            }
        }

        return (
            <React.Fragment>
                {this.showSizePanels(currentSubmission.ticket)}
                <Collapse in={!currentSubmission.subDetailsCollapsed} collapsedSize="35px">
                    <div className={classes.groupHeader}>
                        <div onClick={() => this.toggleGroup('subDetailsCollapsed')}>
                            <Typography variant="body2">{currentSubmission.subDetailsCollapsed ?
                                [<ArrowDropDown key="arrow" style={{ verticalAlign: 'middle' }} />] :
                                [<ArrowDropUp key="arrow" style={{ verticalAlign: 'middle' }} />]}
                                <span>{lang("submissionDetails")}</span>
                            </Typography>
                        </div>
                        {!this.isTicketResubmission() > 0 &&
                            [<Button key="submissionDetailsEdit" className={classes.editButton} variant="text" size="small" onClick={() => this.onEditDetailsClicked()}>{lang("edit")}</Button>]
                        }
                    </div>
                    <div>
                        {submittingTo}
                    </div>
                </Collapse>
                <form id="materialDetailsForm">
                    <Collapse in={!currentSubmission.adDetailsCollapsed} collapsedSize="35px">
                        <div className={classes.groupHeader}>
                            <div onClick={() => this.toggleGroup('adDetailsCollapsed')}>
                                <Typography variant="body2">{currentSubmission.adDetailsCollapsed ?
                                    [<ArrowDropDown key="materialArrow" style={{ verticalAlign: 'middle' }} />] :
                                    [<ArrowDropUp key="materialArrow" style={{ verticalAlign: 'middle' }} />]}
                                    <span style={{ verticalAlign: 'middle' }}>{lang("materialDetails")}</span>
                                </Typography>
                            </div>
                            {materialDetailsAction}
                        </div>
                        <div>
                            {adMetadata}
                        </div>
                    </Collapse>
                </form>
                <form id="contactDetailsForm">
                    <Collapse in={!currentSubmission.contactDetailsCollapsed} collapsedSize="35px">
                        <div className={classes.groupHeader}>
                            <div onClick={() => this.toggleGroup('contactDetailsCollapsed')}>
                                <Typography variant="body2">{currentSubmission.contactDetailsCollapsed ?
                                    [<ArrowDropDown key="contactArrow" style={{ verticalAlign: 'middle' }} />] :
                                    [<ArrowDropUp key="contactArrow" style={{ verticalAlign: 'middle' }} />]}
                                    <span style={{ verticalAlign: 'middle' }}>{lang("additionalInfo")}</span>
                                </Typography>
                            </div>
                            {contactAction}
                        </div>
                        <div>
                            {contactInfo}
                        </div>
                    </Collapse>
                </form>
                {this.getDialogContent()}
                {this.getReportDialog()}
                <TicketClosedDialog open={this.state.ticketState !== TicketState.None} isDeleted={this.state.ticketState === TicketState.Deleted} currentSubmission={this.props.currentSubmission} updateSubmission={this.props.updateSubmission} />
            </React.Fragment>
        );
    }
}

export default withStyles(EditDetails, styles);